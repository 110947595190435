<template>
  <main class="sub-layout-container custom-pb-100">
    <article class="sub-layout-top-container">
      <section class="sub-items-title-wrapper">
        <font color="textTitle" size="24" weight="bold">1:1 문의 조회</font>
      </section>
      <section class="sub-layout-body-container">
        <section class="w-100">
          <table class="table-container w-100">
            <tr>
              <th>
                <uni-font color="textTitle" size="15" weight="semiBold"
                  >USER ID</uni-font
                >
              </th>
              <td class="text-left">
                <uni-font color="textTitle" size="15">
                  <router-link
                    :to="{
                      path: `/user/${userObjectId}`,
                      query: $route.query,
                    }"
                  >
                    {{ boardQuestion.USER_ID }}
                  </router-link>
                </uni-font>
              </td>
              <th>
                <uni-font color="textTitle" size="15" weight="semiBold"
                  >문의일</uni-font
                >
              </th>
              <td class="text-left">
                <uni-font color="textTitle" size="15">{{
                  $moment(boardQuestion.DT).format("YYYY-MM-DD HH:mm:ss")
                }}</uni-font>
              </td>
            </tr>
            <tr>
              <th>
                <uni-font color="textTitle" size="15" weight="semiBold"
                  >카테고리</uni-font
                >
              </th>
              <td class="text-left">
                <uni-font color="textTitle" size="15">{{
                  boardQuestion.TYPE
                }}</uni-font>
              </td>
              <th>
                <uni-font color="textTitle" size="15" weight="semiBold"
                  >답변여부</uni-font
                >
              </th>
              <td class="text-left">
                <b-badge :variant="statusColor(boardQuestion.STATUS)">
                  {{ boardQuestion.STATUS }}
                </b-badge>
              </td>
            </tr>
            <tr>
              <th>
                <uni-font color="textTitle" size="15" weight="semiBold"
                  >제목</uni-font
                >
              </th>
              <td class="text-left" colspan="3">
                <uni-font color="textTitle" size="15">{{
                  boardQuestion.TITLE
                }}</uni-font>
              </td>
            </tr>
            <tbody v-for="(bq, idx) in boardQuestionList" :key="idx">
              <tr v-if="bq.FILES.length > 0">
                <th>
                  <uni-font color="textTitle" size="15" weight="semiBold"
                    >첨부파일</uni-font
                  >
                </th>
                <td class="text-left" colspan="3">
                  <uni-font
                    color="placeholder"
                    size="15"
                    v-for="(file, index) of bq.FILES"
                    :key="index"
                  >
                    <a download :href="file.Location">{{ file.name }}</a>
                  </uni-font>
                </td>
              </tr>
              <tr>
                <th>
                  <uni-font color="textTitle" size="15" weight="semiBold"
                    >내용</uni-font
                  >
                  <uni-button
                    v-if="!bq.ANSWER"
                    class="custom-ml-16"
                    variant="option"
                    :click="() => toReply(bq.LEVEL)"
                  >
                    <uni-font color="placeholder">답변</uni-font>
                  </uni-button>
                </th>
                <td class="question-content" colspan="3">
                  <div v-html="bq.CONTENTS"></div>
                </td>
              </tr>
              <tr v-if="bq.ANSWER">
                <th>
                  <div class="d-flex align-items-center">
                    <uni-font color="textTitle" size="15" weight="semiBold"
                      >답변</uni-font
                    >
                    <uni-button
                      class="custom-ml-16"
                      variant="option"
                      :click="() => toReply(bq.LEVEL)"
                    >
                      <uni-font color="placeholder">수정</uni-font>
                    </uni-button>
                  </div>
                </th>
                <td class="text-left" colspan="3">
                  <div v-html="bq.ANSWER"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section class="w-100 mt-3 d-flex justify-content-end">
          <uni-button variant="secondary" :click="toList" class="custom-mr-10">
            <uni-font color="placeholder" size="15" weight="semiBold"
              >목록</uni-font
            >
          </uni-button>
          <uni-button variant="red" :click="remove" class="custom-mr-10">
            <uni-font color="white" size="15" weight="semiBold">삭제</uni-font>
          </uni-button>
          <!-- <uni-button
            v-if="!lastReply"
            :click="() => toReply(-1)"
          >
            <uni-font color="white" weight="semiBold" size="15">답변</uni-font>
          </uni-button> -->
        </section>
      </section>
    </article>
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import * as BOARD from "@/types/board";
import BoardDeleteModal from "@/components/modal/BoardDeleteModal.vue";

@Component({
  components: {
    BoardDeleteModal,
  },
})
export default class QuestionView extends Vue {
  @Prop() boardQuestion;
  @Prop() boardQuestionList;
  @Prop() lastReply;
  @Prop() userObjectId;

  statusColor(status: string): string {
    if (status === BOARD.QUESTION_STATUS.CHECKING) return "warning";
    if (status === BOARD.QUESTION_STATUS.COMPLETE) return "success";
    else return "";
  }

  toList() {
    this.$emit("toList");
  }

  toReply(level) {
    this.$emit("toReply", level);
  }

  remove() {
    this.$emit("remove");
  }
}
</script>

<style scoped lang="scss">
.answer-container {
  padding: 20px 0;
  min-height: 120px;
  margin: 10px auto 0 auto;

  .answer-body {
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    background-color: $placeholder;
    border: 1px solid $line;
    border-radius: 5px;
    min-height: 100px;
  }

  .question__label {
    width: 100%;
    text-align: left;
    padding: 16px 0;
  }
}
</style>
