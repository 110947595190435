<template>
  <main class="sub-layout-container custom-pb-100">
    <article class="sub-layout-top-container">
      <section class="sub-items-title-wrapper">
        <span class="sub-items-title">1:1 문의 답변</span>
      </section>
      <section class="sub-layout-body-container">
        <table class="table-container">
          <tr>
            <th>
              <uni-font color="textTitle" size="14" weight="normal"
                >USERID</uni-font
              >
            </th>
            <td class="text-left">
              <!-- <a :href="`mailto:${boardQuestion.USER_ID}`">{{ boardQuestion.USER_ID }}</a> -->
              <router-link
                :to="{
                  path: `/admin/user/${boardQuestion.USER_ID}`,
                  query: $route.query,
                }"
              >
                <uni-font color="textSub" size="14">
                  {{ boardQuestion.USER_ID }}</uni-font
                >
              </router-link>
            </td>
            <th>
              <uni-font color="textTitle" size="14" weight="normal"
                >문의일자</uni-font
              >
            </th>
            <td class="question-title">
              <uni-font color="textSub" size="14">{{
                $moment(boardQuestion.DT).format("YYYY-MM-DD HH:mm:ss")
              }}</uni-font>
            </td>
          </tr>
          <tr class="nanumgothic-normal-fuscous-gray-14px">
            <th>
              <uni-font color="textTitle" size="14" weight="normal"
                >카테고리</uni-font
              >
            </th>
            <td class="text-left">
              <uni-font color="textSub" size="14">
                {{ boardQuestion.TYPE }}</uni-font
              >
            </td>
            <th>
              <uni-font color="textTitle" size="14" weight="normal"
                >답변여부</uni-font
              >
            </th>
            <td class="text-left">
              <b-form-select
                v-if="editLevel >= 0"
                class="input-focus-bg"
                v-model="boardQuestionLast.STATUS"
                :options="BOARD.QUESTION_STATUS_OPTION"
              ></b-form-select>
              <b-badge :variant="statusColor(boardQuestion.STATUS)" v-else>
                {{ boardQuestion.STATUS }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th>
              <uni-font color="textTitle" size="14" weight="normal"
                >제목</uni-font
              >
            </th>
            <td class="question-title" colspan="3">
              <uni-font color="textSub" class="text-left w-100" size="14">{{
                boardQuestion.TITLE
              }}</uni-font>
            </td>
          </tr>
          <tbody v-for="(bq, idx) in boardQuestionList" :key="idx">
            <tr v-if="bq.FILES.length > 0">
              <th>
                <uni-font color="textTitle" size="14" weight="normal"
                  >첨부파일</uni-font
                >
              </th>
              <td class="question-title" colspan="3">
                <a :href="bq.FILES[0].Location">
                  <uni-font color="textSub" class="text-left w-100" size="14">{{
                    bq.FILES[0].name
                  }}</uni-font>
                </a>
              </td>
            </tr>
            <tr>
              <th>
                <uni-font color="textTitle" size="14" weight="normal"
                  >내용</uni-font
                >
              </th>
              <td class="question-content" colspan="3">
                <div v-html="bq.CONTENTS"></div>
              </td>
            </tr>
            <tr v-if="bq.ANSWER && getShowEditor(bq, 'content')">
              <th rowspan="2" :class="`numberOne_${idx}`" title="답변 내용">
                <uni-font color="textTitle" size="14" weight="normal"
                  >답변 내용</uni-font
                >
              </th>
              <td class="pt-3 pb-3" style="height: 100%" colspan="3">
                <div v-html="bq.ANSWER"></div>
              </td>
            </tr>
            <tr v-if="bq.ANSWER && getShowEditor(bq, 'edit')" title="답변 수정">
              <th rowspan="2" :class="`numberOne_${idx}`">
                <uni-font color="textTitle" size="14" weight="normal"
                  >답변 수정</uni-font
                >
              </th>
              <td class="pt-3 pb-3" style="height: 100%" colspan="3">
                <VueEditor
                  id="question-editor"
                  v-model="editorHtml"
                  :editorToolbar="customToolbar"
                  @image-added="handleImageAdded"
                  @image-removed="handleImageRemove"
                  :key="uploadImages.length"
                  useCustomImageHandler
                />
              </td>
            </tr>
            <tr
              v-if="!bq.ANSWER && getShowEditor(bq, 'reply')"
              title="답변 등록"
            >
              <th rowspan="2" :class="`numberOne_${idx}`">
                <uni-font color="textTitle" size="14" weight="normal"
                  >답변 등록</uni-font
                >
              </th>
              <td class="pt-3 pb-3" style="height: 100%" colspan="3">
                <VueEditor
                  id="question-editor"
                  v-model="editorHtml"
                  :editorToolbar="customToolbar"
                  @image-added="handleImageAdded"
                  @image-removed="handleImageRemove"
                  :key="uploadImages.length"
                  useCustomImageHandler
                />
              </td>
            </tr>
            <tr v-if="getShowEditor(bq, 'file')">
              <td class="pt-3 pb-3 text-left" colspan="3">
                <ImageUpload @uploadResult="imageAppend" />
              </td>
            </tr>
          </tbody>
        </table>

        <section class="mt-3 d-flex justify-content-end w-100">
          <uni-button
            :click="toPrev"
            variant="secondary-outline"
            class="custom-mr-10"
          >
            <uni-font color="placeholder" size="14">이전</uni-font>
          </uni-button>
          <uni-button
            :click="toList"
            variant="secondary-outline"
            class="custom-mr-10"
          >
            <uni-font color="placeholder" size="14">목록</uni-font>
          </uni-button>
          <uni-button :click="reply">
            <uni-font color="white" size="14">답변</uni-font>
          </uni-button>
        </section>
      </section>
    </article>
  </main>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import * as BOARD from "@/types/board";
import { VueEditor } from "vue2-editor";
import ImageUpload from "@/components/question/ImageUpload.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class QuestionReplyComponent extends Vue {
  @Prop() boardQuestion;
  @Prop() boardQuestionList;
  @Prop() boardQuestionLast;
  @Prop() listId: string;
  BOARD = BOARD;
  editLevel = Number(this.$route.params.level);
  loading = true;
  customToolbar: any[] = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image", "code-block"],
  ];
  uploadImages: File[] = [];
  dataUrlList: string[] = [];

  editorHtml = "";

  uploadEditor = false;

  toPrev() {
    this.$emit("toPrev");
  }

  toList() {
    this.$emit("toList");
  }
  getShowEditor(bq, text) {
    switch (text) {
      case "content":
        if (this.editLevel !== bq.LEVEL) {
          return true;
        } else {
          return false;
        }
      case "edit":
        if (bq.LEVEL === this.editLevel) {
          if (this.editorHtml === "") this.editorHtml = bq.ANSWER;
          return true;
        } else {
          return false;
        }
      case "reply":
        if (bq.LEVEL === this.editLevel) {
          return true;
        } else {
          return false;
        }
      case "file":
        if (bq.LEVEL === this.editLevel) {
          return true;
        } else {
          return false;
        }
    }
  }

  async reply(): Promise<void> {
    try {
      this.loading = true;
      let LEVEL = -1;
      let ANSWER = "";
      if (this.editLevel < 0) {
        LEVEL = this.boardQuestionLast.LEVEL;
        ANSWER = this.editorHtml;
      } else {
        LEVEL = this.boardQuestion.LEVEL;
        ANSWER = this.editorHtml;
      }
      const { STATUS, ROOT_ID, USER_ID } = this.boardQuestionLast;
      if (ANSWER.length < 5) {
        this.$common.makeToast(
          ToastMessage.EMPTY_ANSWER,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      }

      const isFiles = this.dataUrlList.length;
      const replacerANSWER = isFiles ? this.getReplacer(ANSWER) : ANSWER;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append("fileList", file);
      });

      const sendData = {
        STATUS: "답변완료",
        ANSWER: replacerANSWER,
        LEVEL,
        ROOT_ID,
      };

      const { data } = await this.axios.put(
        `/admin/board/Reply/${this.listId}`,
        sendData
      );

      const { result } = data;

      if (this.dataUrlList.length) {
        await this.uploadFile(formData);
      }
      this.loading = false;
      await this.$store.dispatch(
        "socketStore/emitRepliedQuestion",
        this.boardQuestion.USER_ID
      );
      await this.$store.dispatch("socketStore/emitAdminNoReplyQuestion");
      if (result) this.toPrev();
    } catch (e) {
      console.log(e);
    }
  }

  getReplacer(content: string) {
    if (this.dataUrlList.length) {
      this.dataUrlList.forEach((dataUrl, dataIdx) => {
        content = content.replace(dataUrl, `#ReplaceImage.${dataIdx}`);
      });
    }
    return content;
  }

  async uploadFile(formData: FormData) {
    let questionId = "";
    if (this.editLevel < 0) {
      questionId = this.boardQuestionLast._id;
    } else {
      questionId = this.boardQuestion._id;
    }
    const { data } = await this.axios.post(
      `/board/QuestionReply/Reply/${questionId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async upload() {
    let formData = new FormData();
    const { FILES } = this.boardQuestion;
    formData["FILES"] = FILES;
  }

  private imageAppend(data: { file: File; dataUrl: string }) {
    this.uploadImages.push(data.file);
    this.dataUrlList.push(data.dataUrl);

    if (this.editLevel > 0) {
      let bqANSW = this.editorHtml;
      bqANSW += `<p><img alt="문의 답변" src="${data.dataUrl}" style="max-width:360px"></p>`;
      this.editorHtml = bqANSW;
    } else {
      let bqANSW = this.editorHtml;
      bqANSW += `<p><img src="${data.dataUrl}" style="max-width:360px" alt="문의 답변"></p>`;
      this.editorHtml = bqANSW;
    }
  }

  handleImageRemove(dataUrl: string) {
    const idx = this.dataUrlList.indexOf(dataUrl);
    if (idx >= 0) {
      this.dataUrlList.splice(idx, 1);
      this.uploadImages.splice(idx, 1);
    }
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file);
      const sendData = {
        file,
        dataUrl,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ["jpg", "jpeg", "png"];

    const [image, imageType] = type.split("/");
    if (image !== "image" || !typeList.includes(imageType)) {
      this.$common.makeToast(
        ToastMessage.IMAGE_TYPE,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + "");
    });
  }

  statusColor(status: string): string {
    if (status === BOARD.QUESTION_STATUS.CHECKING) return "warning";
    if (status === BOARD.QUESTION_STATUS.COMPLETE) return "success";
    else return "";
  }
}
</script>

<style scoped>
#question-editor {
  height: 350px;
}
</style>
