<template>
  <article class="help-card">
    <div
      :style="{
        alignItems: thumbnailsAlign === 'left' ? 'flex-start' : 'flex-end',
      }"
      class="thumbnail-wrapper"
    >
      <img
        :alt="title"
        :src="require(`@/assets/images/thumbnails/${thumbnails}.svg`)"
        :style="{
          marginLeft: thumbnailsAlign === 'left' ? '2em' : '0px',
          marginRight: thumbnailsAlign === 'right' ? '2em' : '0px',
        }"
      />
      <div class="thumbnail-description-label">
        {{ title }}
      </div>
    </div>
    <div class="help-content-container" v-html="content" />
  </article>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class HelpCard extends Vue {
  @Prop() content: string;
  @Prop() thumbnails: string;
  @Prop() thumbnailsAlign: string;
  @Prop() title: string;

  images = ["1", "2", "4", "5"];
}
</script>

<style lang="scss" scoped>
.help-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .thumbnail-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    img {
      //max-width: 333px;
      object-fit: cover;
      margin-left: 2em;
      height: 214px;
    }

    .thumbnail-description-label {
      background: linear-gradient(90deg, #7244f6, #104cf4);
      color: white;
      width: 333px;
      height: 45px;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      font-weight: bold;
      position: relative;
      font-size: 20px;
      bottom: 2px;
    }
  }

  .help-content-container {
    color: #333333;
    text-align: left;
    padding-left: 20px;
    font-size: 17px;
    //width: 333px;
  }
}
</style>

<style>
.VueCarousel-dot {
  background-color: #98adff !important;
}

.VueCarousel-dot--active {
  width: 28px !important;
  background: linear-gradient(to right, #7244f6, #104cf4) !important;
  border-radius: 20px !important;
  height: 10px !important;
  padding: 0 !important;
}
</style>
