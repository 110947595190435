<template>
  <div class="login-container">
    <div class="login-layout">
      <LoginBanner />
      <LoginComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LoginBanner from "@/components/login/LoginBanner.vue";
import LoginComponent from "@/components/login/LoginComponent.vue";

@Component({
  components: { LoginComponent, LoginBanner },
})
export default class Login extends Vue {}
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f5f7ff;
  z-index: 10;

  .login-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    justify-items: end;
  }
}
</style>
