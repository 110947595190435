export const loginServiceGuideData = [
  {
    title: '설문조사 셀프 제작 시스템',
    thumbnails: '1',
    thumbnailsAlign: 'left',
    content: `문항 등록부터 데이터 수집까지 모든 과정을 <br /> 
직접 진행할 수 있으며, 사용자 중심의 UI로 누구나 쉽게 이용할 수 있습니다.`
  },
  {
    title: '문항 추천 서비스',
    thumbnails: '2',
    thumbnailsAlign: 'left',
    content: `어떤 질문을 해야 할지 막막하실 때, <br />
            주제별 추천 문항을 제안해 드립니다. <br />
            추천 문항을 활용하여 설문을 진행해 보세요.`
  },
  {
    title: '패널 활용 데이터 수집',
    thumbnails: '3',
    thumbnailsAlign: 'left',
    content: `롯데멤버스의 리서치 패널 '라임'과 연계된 <br /> 인프라를 통해 데이터 자동 수집이 가능합니다. 필요한 샘플을 합리적인 가격으로 이용해보세요.`
  },
  {
    title: '구매 데이터 기반 정밀 타켓팅',
    thumbnails: '4',
    thumbnailsAlign: 'right',
    content: `성/연령/지역 정보 외 롯데멤버스만의 <br />
              구매 데이터 정보를 활용한 정밀 타켓팅이 <br />  가능합니다.`
  },
  {
    title: '다양한 결과물 제공',
    thumbnails: '5',
    thumbnailsAlign: 'right',
    content: `실시간 현황과 다양한 결과를 확인할 수 있습니다.
            최적의 인사이트를 도출해 보세요. <br /><br />
            <div class='login-service-card-description-wrapper'>
      <b class='login-service-card-description-text'>문항별 실시간 분석 현황 / PPT report / 교차 분</b>
            <b class='login-service-card-description-text'>석 테이블 / SPSS 명령문 / Raw data</b>      
</div>`
  },
];