<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import RequestAuthNumber from "./RequestAuthNumber.vue";
import SetNewPw from "@/components/user/SetNewPw.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: {
    SetNewPw,
    RequestAuthNumber,
  },
})
export default class FindId extends Vue {
  userId = "";
  $refs: Vue["$refs"] & {
    findIdAuthNumber: RequestAuthNumber;
  };

  search() {
    const auth = this.$refs.findIdAuthNumber.authNumberCheck();
    if (!auth) {
      this.$common.makeToast(
        ToastMessage.FIND_ID,
        ToastVariant.WARNING,
        this.$bvToast
      );
      return;
    } else {
      this.userId = this.$refs.findIdAuthNumber.userId;
    }
  }

  reset() {
    this.userId = "";
    this.$refs.findIdAuthNumber.reset();
  }

  questionModalOpen() {
    this.$bvModal.show("question-write-modal");
  }
}
</script>

<template>
  <b-card>
    <b-card-body>
      <b-row>
        <template v-if="userId === ''">
          <b-col cols="12" class="mt-3">
            <font size="15" color="textTitle">
              아이디가 기억나지 않으시나요? <br />
              서베이메이트는 이메일 아이디를 지원하고 있습니다.
            </font>
          </b-col>
          <section class="w-100 mt-4">
            <RequestAuthNumber ref="findIdAuthNumber" :find-password="false" />
          </section>
          <section class="mt-4 d-flex justify-content-end w-100">
            <!--            <uni-button-->
            <!--              variant="secondary"-->
            <!--              :click="questionModalOpen"-->
            <!--              class="custom-mr-10"-->
            <!--            >-->
            <!--              <font color="placeholder" weight="bold" size="16">-->
            <!--                1:1 문의-->
            <!--              </font>-->
            <!--            </uni-button>-->
            <uni-button :click="search">
              <font color="white" size="16" weight="bold"> 아이디 찾기 </font>
            </uni-button>
          </section>
        </template>
        <template v-else>
          <b-col cols="12" class="mt-3 text-left display-inline">
            <font size="15" color="textTitle">
              회원님의 아이디는 <b>{{ userId.toLowerCase() }}</b> 입니다.
            </font>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end mt-5">
            <!--            <uni-button-->
            <!--              variant="secondary"-->
            <!--              :click="questionModalOpen"-->
            <!--              class="custom-mr-10"-->
            <!--            >-->
            <!--              <font color="placeholder" weight="bold" size="16">-->
            <!--                1:1 문의-->
            <!--              </font>-->
            <!--            </uni-button>-->
            <uni-button :click="() => $router.push(`/login`)">
              <font color="white" size="16" weight="bold"> 로그인 </font>
            </uni-button>
          </b-col>
        </template>
      </b-row>
    </b-card-body>
  </b-card>
</template>
