<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col v-if="authFlag === false" class="mt-3" cols="12">
          <font color="textTitle" size="15">
            비밀번호가 기억나지 않으시나요? <br />
            서베이메이트는 특수문자를 포함한 8글자 이상의 비밀번호를 사용합니다.
          </font>

          <section class="text-left mt-4 w-100">
            <b-form-group
              label="이메일 아이디"
              label-align="left"
              label-class="pl-3"
              label-cols="2"
            >
              <b-form class="w-100" inline>
                <font color="textTitle" size="15">
                  <b-form-input
                    v-model="inpEmailId"
                    class="find-fw-email-input input-focus-bg"
                  />
                </font>
                <font color="textTitle" size="15">
                  <b-input-group class="selected-box-group" prepend="@">
                    <b-form-input
                      v-model="inpEmailAddress"
                      :disabled="selectEmail !== ''"
                      class="find-fw-domain-input input-focus-bg"
                    />
                  </b-input-group>
                </font>
                <font color="textTitle" size="15">
                  <b-form-select
                    v-model="selectEmail"
                    :options="emailArray"
                    class="find-fw-select input-focus-bg"
                    @change="changeEmail"
                  />
                </font>
              </b-form>
            </b-form-group>
          </section>
          <section class="w-100 mt-4">
            <RequestAuthNumber
              ref="findPwAuthNumber"
              :find-password="true"
              :req-user-id="userId"
            />
          </section>
          <section class="d-flex justify-content-end w-100 mt-4">
            <!--            <uni-button-->
            <!--              :click="questionModalOpen"-->
            <!--              class="custom-mr-10"-->
            <!--              variant="secondary"-->
            <!--            >-->
            <!--              <font color="placeholder" size="16" weight="bold">-->
            <!--                1:1 문의-->
            <!--              </font>-->
            <!--            </uni-button>-->
            <uni-button :click="search">
              <font color="white" size="16" weight="bold"> 비밀번호 찾기 </font>
            </uni-button>
          </section>
        </b-col>
        <b-col v-else class="mt-3" cols="12">
          <SetNewPw :user-id="userId" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import type { IArrayOption } from "@/interface/common";
import RequestAuthNumber from "./RequestAuthNumber.vue";
import SetNewPw from "@/components/user/SetNewPw.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: {
    SetNewPw,
    RequestAuthNumber,
  },
})
export default class FindPw extends Vue {
  inpEmailId = "";
  inpEmailAddress = "";
  selectEmail = "";
  emailArray: Array<IArrayOption> = [
    { value: "", text: "직접 입력" },
    { value: "naver.com", text: "naver.com" },
    { value: "daum.net", text: "daum.net" },
    { value: "hanmail.net", text: "hanmail.net" },
    { value: "gmail.com", text: "gmail.com" },
    { value: "nate.com", text: "nate.com" },
  ];
  authFlag = false;

  $refs: Vue["$refs"] & {
    findPwAuthNumber: RequestAuthNumber;
  };

  get userId(): string {
    return `${this.inpEmailId}@${this.inpEmailAddress}`;
  }

  changeEmail(): void {
    this.inpEmailAddress = this.selectEmail;
  }

  async search() {
    const email = this.userId;
    const result = await this.emailCheck(email);
    if (!result) {
      this.$common.makeToast(
        ToastMessage.NOT_EXIST_EMAIL,
        ToastVariant.WARNING,
        this.$bvToast
      );
      return;
    }

    this.authFlag = this.$refs.findPwAuthNumber.authNumberCheck();
    if (!this.authFlag) {
      this.$common.makeToast(
        ToastMessage.FIND_PASSWORD,
        ToastVariant.WARNING,
        this.$bvToast
      );
      return;
    }
  }

  async emailCheck(userId: string): Promise<boolean> {
    const { data } = await this.axios.post(`/user/duplicate-id/${userId}`);
    const { exists } = data;
    return exists;
  }

  reset() {
    this.inpEmailId = "";
    this.inpEmailAddress = "";
    this.selectEmail = "";
    this.authFlag = false;
    this.$refs.findPwAuthNumber.reset();
  }

  questionModalOpen() {
    this.$bvModal.show("question-write-modal");
  }
}
</script>

<style lang="scss" scoped>
.selected-box-group {
  width: 310px !important;
}
.find-fw-email-input {
  width: 210px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.find-fw-select {
  text-align: center !important;
  border-radius: 0 0.25rem 0.25rem 0;
}
.find-fw-domain-input {
  border-radius: 0;
  border-right: 1px solid $white;
}
</style>
