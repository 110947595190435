<template>
  <span>
     <svg :height="height" :width="width" fill="none" viewBox="0 0 187 28" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9635_4226)">
<path :fill="fillText" d="M37.3583 21.9805V18.7205C38.3983 19.0605 39.4483 19.3305 40.5183 19.5405C41.5783 19.7505 42.5683 19.8605 43.4683 19.8605C45.1183 19.8605 46.2983 19.6305 46.9983 19.1805C47.6983 18.7205 48.0483 18.0805 48.0483 17.2605C48.0483 16.6005 47.7083 16.0105 47.0383 15.4805C46.3683 14.9505 45.1383 14.4105 43.3683 13.8505C41.7683 13.3405 40.5083 12.8105 39.5883 12.2405C38.6683 11.6805 38.0083 11.0305 37.5983 10.3205C37.1883 9.60047 36.9883 8.76047 36.9883 7.80047C36.9883 6.10047 37.6683 4.74047 39.0383 3.74047C40.3983 2.74047 42.4283 2.23047 45.1283 2.23047C46.9383 2.23047 48.6283 2.45047 50.2183 2.89047V6.08047C49.3283 5.84047 48.4883 5.68047 47.6783 5.57047C46.8683 5.47047 45.9983 5.42047 45.0583 5.42047C42.1983 5.42047 40.7583 6.17047 40.7583 7.67047C40.7583 8.14047 40.8783 8.54047 41.1283 8.87047C41.3783 9.20047 41.8383 9.53047 42.5183 9.86047C43.1983 10.1905 44.2283 10.5705 45.6083 11.0105C47.8083 11.7205 49.3983 12.5605 50.3783 13.5305C51.3583 14.5005 51.8483 15.7105 51.8483 17.1605C51.8483 18.3105 51.5483 19.3205 50.9483 20.2105C50.3483 21.1005 49.4383 21.7905 48.2083 22.3005C46.9783 22.8105 45.4583 23.0605 43.6283 23.0605C41.3283 23.0605 39.2383 22.7105 37.3683 22.0005L37.3583 21.9805Z"/>
<path :fill="fillText" d="M56.9169 22.2491C55.9269 21.7191 55.1569 20.9591 54.6169 19.9591C54.0669 18.9591 53.7969 17.7691 53.7969 16.3991V8.53906H57.3269V16.5991C57.3269 17.6691 57.5869 18.4991 58.1069 19.0891C58.6269 19.6791 59.3769 19.9791 60.3469 19.9791C61.3169 19.9791 62.0669 19.6791 62.5869 19.0891C63.1069 18.4991 63.3669 17.6691 63.3669 16.5991V8.53906H66.8969V16.3991C66.8969 17.7691 66.6269 18.9491 66.0869 19.9391C65.5469 20.9291 64.7869 21.6991 63.7969 22.2291C62.8069 22.7591 61.6569 23.0291 60.3469 23.0291C59.0369 23.0291 57.9069 22.7691 56.9169 22.2391V22.2491Z"/>
<path :fill="fillText" d="M72.4077 23.0408H68.8477V14.3008C68.8477 12.3508 69.2777 10.9008 70.1477 9.96078C71.0177 9.02078 72.3577 8.55078 74.1777 8.55078H76.5877V12.0508H74.3977C73.7377 12.0508 73.2377 12.2308 72.9077 12.5908C72.5777 12.9508 72.4077 13.5808 72.4077 14.4708V23.0508V23.0408Z"/>
<path :fill="fillText" d="M92.8089 8.53906L87.1889 23.0391H83.7489L78.1289 8.53906H81.6689L85.3189 18.4391C85.3789 18.5891 85.5789 18.5891 85.6389 18.4391L89.2789 8.53906H92.8189H92.8089Z"/>
<path :fill="fillText" d="M94.8578 21.0386C93.4578 19.7086 92.7578 17.8986 92.7578 15.6186C92.7578 14.1386 93.0478 12.8286 93.6378 11.6886C94.2278 10.5486 95.0578 9.65859 96.1478 9.01859C97.2278 8.37859 98.4678 8.05859 99.8678 8.05859C101.268 8.05859 102.528 8.37859 103.588 9.00859C104.658 9.63859 105.478 10.5286 106.058 11.6686C106.638 12.8086 106.928 14.1286 106.928 15.6286V17.0286H96.5478C96.9278 19.0586 98.5478 20.0786 101.428 20.0786C102.708 20.0786 103.988 19.8886 105.268 19.5086V22.4286C104.078 22.8486 102.688 23.0486 101.098 23.0486C98.3378 23.0486 96.2578 22.3786 94.8578 21.0486V21.0386ZM103.218 14.2386C103.118 13.3186 102.758 12.5786 102.148 12.0286C101.538 11.4786 100.778 11.1986 99.8678 11.1986C98.9578 11.1986 98.2078 11.4786 97.5878 12.0386C96.9678 12.5986 96.6278 13.3386 96.5378 14.2386H103.208H103.218Z"/>
<path :fill="fillText" d="M118.797 8.53906L115.137 17.3291C115.077 17.4791 114.867 17.4691 114.817 17.3291L111.617 8.54906H107.497L112.887 22.3991L112.827 22.5391C112.557 23.1491 112.247 23.5891 111.917 23.8591C111.587 24.1191 111.137 24.2591 110.577 24.2591C110.187 24.2591 109.787 24.1791 109.387 24.0291C108.987 23.8791 108.637 23.6591 108.327 23.3891L106.867 26.2291C107.307 26.6191 107.877 26.9291 108.577 27.1491C109.277 27.3691 109.987 27.4791 110.697 27.4791C111.537 27.4791 112.297 27.3491 112.987 27.0991C113.677 26.8391 114.307 26.3991 114.877 25.7691C115.447 25.1391 115.957 24.2691 116.397 23.1591L122.607 8.55906H118.767L118.797 8.53906Z"/>
<path :fill="fillText" d="M127.408 23.04H124.168V8.54H127.408V9.79C127.698 9.36 128.158 9 128.778 8.7C129.398 8.4 130.078 8.25 130.798 8.25C132.638 8.25 133.998 8.96 134.888 10.37C135.318 9.74 135.908 9.23 136.658 8.84C137.408 8.45 138.208 8.25 139.078 8.25C140.728 8.25 142.038 8.79 143.008 9.86C143.978 10.93 144.458 12.43 144.458 14.33V23.02H141.218V14.33C141.218 13.48 140.978 12.77 140.508 12.22C140.038 11.67 139.388 11.39 138.578 11.39C137.768 11.39 137.128 11.67 136.648 12.22C136.158 12.77 135.918 13.48 135.918 14.33V23.02H132.678V14.4C132.678 13.48 132.438 12.75 131.968 12.2C131.498 11.65 130.858 11.38 130.058 11.38C129.258 11.38 128.658 11.65 128.168 12.2C127.678 12.75 127.438 13.48 127.438 14.4V23.02L127.408 23.04Z"/>
<path :fill="fillText" d="M149.698 22.0787C148.658 21.4387 147.848 20.5588 147.278 19.4388C146.708 18.3188 146.418 17.0487 146.418 15.6287C146.418 14.2087 146.708 12.9187 147.288 11.7987C147.868 10.6788 148.668 9.79875 149.688 9.16875C150.708 8.53875 151.858 8.21875 153.138 8.21875C154.008 8.21875 154.788 8.37875 155.478 8.69875C156.168 9.01875 156.718 9.44875 157.128 9.98875V8.44875H160.578V18.8488C160.578 19.2688 160.688 19.5488 160.898 19.6988C161.108 19.8388 161.408 19.8988 161.778 19.8588V22.7487C161.438 22.8187 161.068 22.8488 160.678 22.8488C159.928 22.8488 159.268 22.6887 158.708 22.3787C158.148 22.0687 157.728 21.6388 157.458 21.0988C157.078 21.6687 156.508 22.1388 155.718 22.4987C154.938 22.8587 154.128 23.0388 153.288 23.0388C151.928 23.0388 150.718 22.7188 149.678 22.0787H149.698ZM156.128 18.7587C156.798 17.9688 157.138 16.9187 157.138 15.6187C157.138 14.7787 156.988 14.0287 156.678 13.3687C156.368 12.7087 155.948 12.1988 155.398 11.8387C154.848 11.4787 154.238 11.2987 153.558 11.2987C152.518 11.2987 151.658 11.6987 150.988 12.4887C150.318 13.2787 149.978 14.3287 149.978 15.6287C149.978 16.9288 150.318 17.9788 150.988 18.7688C151.658 19.5588 152.518 19.9587 153.558 19.9587C154.598 19.9587 155.458 19.5588 156.128 18.7688V18.7587Z"/>
<path :fill="fillText" d="M170.438 23.0392C168.458 23.0392 166.918 22.7492 165.798 22.1692C164.678 21.5892 163.898 20.7292 163.428 19.5692C162.968 18.4192 162.738 16.8992 162.738 15.0092V4.69922H166.268V8.53922H170.438V11.7592H166.268V15.3392C166.268 16.3792 166.388 17.2292 166.628 17.8792C166.868 18.5392 167.288 19.0392 167.898 19.3792C168.498 19.7192 169.348 19.8792 170.438 19.8692V23.0392Z"/>
<path :fill="fillText" d="M173.979 21.0386C172.579 19.7086 171.879 17.8986 171.879 15.6186C171.879 14.1386 172.169 12.8286 172.759 11.6886C173.349 10.5486 174.179 9.65859 175.269 9.01859C176.349 8.37859 177.589 8.05859 178.989 8.05859C180.389 8.05859 181.649 8.37859 182.709 9.00859C183.779 9.63859 184.599 10.5286 185.179 11.6686C185.759 12.8086 186.049 14.1286 186.049 15.6286V17.0286H175.669C176.049 19.0586 177.669 20.0786 180.549 20.0786C181.829 20.0786 183.109 19.8886 184.389 19.5086V22.4286C183.199 22.8486 181.809 23.0486 180.219 23.0486C177.459 23.0486 175.379 22.3786 173.979 21.0486V21.0386ZM182.339 14.0986C182.239 13.1986 181.879 12.4786 181.269 11.9386C180.659 11.3986 179.899 11.1286 178.989 11.1286C178.079 11.1286 177.329 11.3986 176.709 11.9486C176.089 12.4986 175.749 13.2186 175.659 14.0986H182.329H182.339Z"/>
<path d="M19.76 0H5.85C2.61913 0 0 2.61913 0 5.85V19.76C0 22.9909 2.61913 25.61 5.85 25.61H19.76C22.9909 25.61 25.61 22.9909 25.61 19.76V5.85C25.61 2.61913 22.9909 0 19.76 0Z" fill="url(#paint0_linear_9635_4226)"/>
<path d="M19.7604 4.13891H8.59038C6.30038 4.13891 4.31038 5.76891 3.92038 8.01891C3.41038 10.9789 5.65038 13.5589 8.52038 13.5989C7.05038 13.5589 5.91038 12.1889 6.29038 10.6389C6.54038 9.58891 7.50038 8.86891 8.58038 8.86891H19.7604C20.8704 8.86891 21.7704 7.96891 21.7704 6.85891V6.13891C21.7704 5.02891 20.8704 4.12891 19.7604 4.12891V4.13891Z" fill="white"/>
<path d="M17.0898 12.0078C18.5598 12.0478 19.6998 13.4178 19.3198 14.9678C19.0698 16.0178 18.1098 16.7378 17.0298 16.7378H5.84984C4.73984 16.7378 3.83984 17.6378 3.83984 18.7478V19.4678C3.83984 20.5778 4.73984 21.4778 5.84984 21.4778H17.0198C19.3098 21.4778 21.2998 19.8478 21.6898 17.5978C22.1998 14.6378 19.9598 12.0578 17.0898 12.0178V12.0078Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_9635_4226" gradientUnits="userSpaceOnUse" x1="0" x2="25.61" y1="12.8" y2="12.8">
  <stop :stop-color="isGray ? '#ccc' : '#8943F7'" offset="0"/>
  <stop :stop-color="isGray ? '#ccc' : '#6945F6'" offset="0.17"/>
  <stop :stop-color="isGray ? '#ccc' :'#4248F5'" offset="0.42"/>
  <stop :stop-color="isGray ? '#ccc' :'#254BF4'" offset="0.65"/>
  <stop :stop-color="isGray ? '#ccc' :'#144CF4'" offset="0.85"/>
  <stop :stop-color="isGray ? '#ccc' :'#0E4DF4'" offset="1"/>
</linearGradient>
<clipPath id="clip0_9635_4226">
<rect fill="white" height="27.46" width="186.05"/>
</clipPath>
</defs>
</svg>
  </span>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class LimeLogo extends Vue {
  @Prop({default: '187'}) width?: string | number;
  @Prop({default: '28'}) height?: string | number;
  @Prop({default: 'default'}) color?: string;

  get isGray(): boolean {
    return this.color === 'gray';
  }

  get fillText(): string {
    if (this.isGray) return '#ccc';
    if (this.color === 'default' || !this.color) return '#1E2247';
    return '#1E2247';
  }
}
</script>

<style scoped>

</style>