<template>
  <div class="text-center custom-pt-200">
    <h1>404 NOT FOUND</h1>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NotFoundPage extends Vue {}
</script>

<style scoped></style>
