<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FindId from "@/components/user/FindId.vue";
import FindPw from "@/components/user/FindPw.vue";

@Component({
  components: {
    FindPw,
    FindId,
  },
})
export default class FindAccount extends Vue {
  tabIndex = 0;
  $refs: Vue["$refs"] & {
    findId: FindId;
    findPw: FindPw;
  };

  findIdReset() {
    if (this.tabIndex === 0) return;
    this.$refs.findId?.reset();
  }

  findPwReset() {
    if (this.tabIndex === 1) return;
    this.$refs.findPw?.reset();
  }
}
</script>

<template>
  <section class="find-account-tab p-4">
    <BTabs justified lazy pills v-model="tabIndex">
      <BTab title="아이디 찾기" lazy active class="mt-0" @click="findIdReset">
        <FindId ref="findId" />
      </BTab>
      <BTab title="비밀번호 찾기" lazy @click="findPwReset">
        <FindPw ref="findPw" />
      </BTab>
    </BTabs>
  </section>
</template>

<style lang="scss">
.find-account-tab {
  .nav-link {
    font-family: $nanumSquare !important;
  }
}
</style>
