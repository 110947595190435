<template>
  <main class="sub-layout-container">
    <section class="sub-layout-top-container">
      <ProfileInfo />
      <ProjectInfo />
      <QuestionInfo />
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProfileInfo from "@/components/user/component/ProfileInfo.vue";
import PaymentInfo from "@/components/user/component/PaymentInfo.vue";
import QuestionInfo from "@/views/user/QuestionInfo.vue";
import ProjectInfo from "@/components/user/component/ProjectInfo.vue";

@Component({
  components: {
    QuestionInfo,
    ProfileInfo,
    ProjectInfo,
  },
})
export default class UserView extends Vue {
  created() {
    this.$store.dispatch(
      "socketStore/emitAdminCheckUser",
      this.$route.params.id
    );
  }
}
</script>
<style scoped></style>
