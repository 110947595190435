<template>
  <div>
    <question-specific
      :board-question="boardQuestion"
      :board-question-list="boardQuestionList"
      :last-reply="lastReply"
      :user-object-id="userObjectId"
      @toList="toList"
      @toReply="toReply"
      @remove="remove"
    />
    <board-delete-modal @toList="redirectUser" :list-id="listId" />
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import * as BOARD from "@/types/board";
import BoardDeleteModal from "@/components/modal/BoardDeleteModal.vue";
import QuestionSpecific from "@/components/question/QuestionSpecific.vue";

@Component({
  components: {
    BoardDeleteModal,
    QuestionSpecific,
  },
})
export default class QuestionView extends Vue {
  lastReply = false;
  listId = "";
  loading = true;
  boardQuestion: BOARD.IBOARDQuestion = BOARD.boardQuestionInit();
  boardQuestionList: BOARD.IBOARDQuestion[] = [];
  userObjectId = "";

  created() {
    const { listId } = this.$route.params as { listId: string };
    this.listId = listId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(
        `/board/QuestionList/Read/${this.listId}`
      );
      const { question, OBJECT_ID } = data;
      this.userObjectId = OBJECT_ID;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.lastReply = this.boardQuestionList[this.boardQuestionList.length - 1]
        .ANSWER
        ? true
        : false;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  statusColor(status: string): string {
    if (status === BOARD.QUESTION_STATUS.CHECKING) return "warning";
    if (status === BOARD.QUESTION_STATUS.COMPLETE) return "success";
    else return "";
  }

  redirectUser() {
    this.$router.replace({
      path: `/user/${this.boardQuestion.USER_ID}`,
      query: this.$route.query,
    });
  }

  toList() {
    this.$router.push({ path: "/question", query: this.$route.query });
  }

  toReply(level) {
    this.$router.push({
      path: `/question-reply/${this.listId}/${level}`,
    });
  }

  remove(): void {
    this.$bvModal.show("board-remove-modal");
  }
}
</script>

<style scoped>
.question-title span {
  height: 21px;
}
</style>
