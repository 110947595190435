<template>
  <b-modal
    id="login-password-change-modal"
    :lazy="true"
    centered
    hide-footer
    hide-header
    scrollable
    size="lg"
    @hidden="closeModal"
  >
    <div class="password-change-container">
      <lime-logo :height="24" :width="164" />
      <font
        size="22"
        type="square"
        weight="semiBold"
        class="custom-pt-20 custom-pb-20"
        >비밀번호 변경</font
      >
      <div
        v-if="passwordLimit"
        class="d-flex align-items-center"
        style="background-color: #f2dede; padding: 30px; border-radius: 10px"
      >
        <font-awesome-icon
          :icon="['fas', 'lock']"
          class="fa-2x"
          style="color: #ff6666"
        />
        <div class="custom-pl-20">
          <div>
            <font size="16" color="red"
              >90일 동안 비밀번호를 변경하지 않았습니다.</font
            >
          </div>
          <font size="16" color="red"
            >개인정보 보호를 위하여 비밀번호를 변경해야 합니다.</font
          >
        </div>
      </div>
      <div
        v-if="passwordChangeRequired"
        class="d-flex align-items-center"
        style="background-color: #f2dede; padding: 30px; border-radius: 10px"
      >
        <font-awesome-icon
          :icon="['fas', 'lock']"
          class="fa-2x"
          style="color: #ff6666"
        />
        <div class="custom-pl-20">
          <div>
            <font size="16" color="red"
              >최초 로그인시에는 개인정보 보호를 위하여 비밀번호를 변경해야
              합니다.</font
            >
          </div>
        </div>
      </div>
      <form
        class="d-flex flex-column align-items-center w-100"
        style="
          background-color: #f9f9f9;
          padding: 20px 0;
          margin: 20px 0;
          border-radius: 10px;
        "
      >
        <div class="input-wrapper">
          <b-form-group
            class="position-relative text-left"
            label-align-lg="right"
            label-class="label-absolute"
            label-cols="3"
            label-for="input-current-pwd"
          >
            <template #label>
              <b-form-text>현재 비밀번호</b-form-text>
            </template>
            <template #default>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-white">
                    <b-icon-lock />
                  </b-input-group-text>
                </template>
                <template>
                  <b-form-input
                    id="input-current-pwd"
                    v-model="currentUserPwd"
                    autocomplete="off"
                    class="input-focus-bg"
                    placeholder="현재 비밀번호"
                    type="password"
                    @keypress.prevent.enter
                  />
                </template>
              </b-input-group>
            </template>
          </b-form-group>
        </div>
        <div class="input-wrapper">
          <b-form-group
            :invalid-feedback="validationPwdMessage"
            :state="validationPassword"
            class="position-relative text-left"
            label-align-lg="right"
            label-class="label-absolute"
            label-cols="3"
            label-for="input-pwd"
          >
            <template #label>
              <b-form-text>새 비밀번호</b-form-text>
            </template>
            <template #description>
              <b-form-text
                >영문 대/소문자,숫자,특수문자($,@,!,%,*,#,?,&)를 포함한 8글자
                이상 20글자 이하으로 적어주세요</b-form-text
              >
            </template>
            <template #default>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-white">
                    <b-icon-lock />
                  </b-input-group-text>
                </template>
                <template>
                  <b-form-input
                    id="input-pwd"
                    v-model="userPwd"
                    :state="validationPassword"
                    autocomplete="off"
                    class="input-focus-bg"
                    placeholder="특수문자를 포함한 8글자 이상"
                    type="password"
                    @keypress.prevent.enter
                  />
                </template>
              </b-input-group>
            </template>
          </b-form-group>
        </div>
        <div class="input-wrapper">
          <b-form-group
            :invalid-feedback="validationPwdConfMessage"
            :state="validationConfPassword"
            class="position-relative text-left"
            label-align-lg="right"
            label-class="label-absolute"
            label-cols="3"
            label-for="input-pwd-conf"
          >
            <template #label>
              <b-form-text>새 비밀번호 확인</b-form-text>
            </template>
            <template #description>
              <b-form-text>비밀번호를 한번 더 입력해주세요</b-form-text>
            </template>
            <template #default>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-white">
                    <b-icon-shield-check />
                  </b-input-group-text>
                </template>
                <template>
                  <b-form-input
                    id="input-pwd-conf"
                    v-model="userPwdConf"
                    :state="validationConfPassword"
                    autocomplete="off"
                    class="input-focus-bg"
                    placeholder="특수문자를 포함한 8글자 이상"
                    type="password"
                    @keypress.prevent.enter
                  />
                </template>
              </b-input-group>
            </template>
          </b-form-group>
        </div>
      </form>
      <div class="d-flex justify-content-end">
        <uni-button
          width="100"
          height="40"
          class="custom-ml-10"
          :click="changePassword"
          >변경</uni-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import LimeLogo from "@/components/icons/LimeLogo.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: { LimeLogo },
})
export default class LoginPasswordChangeModal extends Vue {
  @Prop() userId: string;
  @Prop() userOriginPwd: string;
  @Prop() passwordLimit: boolean;
  @Prop() passwordChangeRequired: boolean;
  boardQuestionId = "";
  currentUserPwd = "";
  userPwd = "";
  userPwdConf = "";

  get validationPassword(): boolean | null {
    const pwdRegExp =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$@!%*#?&])[a-zA-Z\d$@!%*#?&]{8,20}$/;

    const isOkPwdReg = pwdRegExp.test(this.userPwd);
    const isNull = this.userPwd.length === 0;

    if (isNull) return null;
    return isOkPwdReg;
  }

  get validationPwdMessage(): string | void {
    return "비밀번호의 형식을 맞춰주십시오.";
  }

  get validationPwdConfMessage(): string | void {
    return "비밀번호가 같지 않습니다.";
  }

  get validationConfPassword(): boolean | null {
    const isNull = this.userPwdConf.length === 0;
    if (isNull) return null;
    return this.userPwd === this.userPwdConf;
  }

  validPassword() {
    if (this.userPwd !== this.userPwdConf) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK1,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }

    if (this.userPwd.length < 8) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK2,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }

    if (this.userPwd.length > 20) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK3,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }
    return true;
  }

  @Emit("closeModal")
  closeModal() {
    this.currentUserPwd = "";
    this.userPwd = "";
    this.userPwdConf = "";
  }

  async userDataSaveInStore(data) {
    await this.$store.dispatch("login", { data });
    await this.$store.commit("setLastLoginDate", data.lastLoginDate);
    await this.$router.push("/list");
  }

  async changePassword() {
    try {
      if (!this.validPassword()) return false;
      const { data } = await this.axios({
        url: `/admin/user/90-password`,
        method: "PATCH",
        data: {
          userId: this.userId,
          userPwd: this.currentUserPwd,
          userNewPwd: this.userPwd,
        },
      });
      const { result, message, failFlag } = data;
      if (result) {
        await this.userDataSaveInStore(data);
        this.$common.makeToast(
          "비밀번호가 변경되었습니다.",
          ToastVariant.SUCCESS,
          this.$bvToast
        );
      } else {
        if (!failFlag) {
          this.$common.makeToast(message, ToastVariant.DANGER, this.$bvToast);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
</script>

<style lang="scss" scoped>
.password-change-container {
  font-family: NanumSquare !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;

  .input-wrapper {
    width: 60%;

    .policy:hover p,
    .private:hover p {
      font-weight: 700;
    }
  }
}
</style>
