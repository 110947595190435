import { ins } from "@/utils/axios";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IQuestionValue } from "@/interface/admin/question";

enum CREATE_REGION {
  ALL = 4, // 전국
  SEOUL = 1, // 서울
  IC_GG = 2, //인천, 경기
  METRO_5 = 3, // 수도권 제외 5대 광역시
}

interface ProjectParams {
  pageNum: number;
  perPage: number;
  searchType: string;
  search: string;
  searchDateStart: string;
  searchDateEnd: string;
}

interface ProjectListInfo {
  result: boolean;
  count: number;
  //TODO: list interface 정의
  list: any;
  numberOfPages: number;
}

interface PaymentInfo {
  _id: string;
  projectId: number;
  receipt_id: string;
  event: string;
  status: number;
  message: string;
  userId: string;
  data: any;
  regDate: Date;
}

export interface SimpleSurveyChecklistInfo {
  _id?: string;
  privateInfo?: boolean;
  sensitiveInfo?: boolean;
  political?: boolean;
  comment?: string;
}

export interface SimpleSurveyQuota {
  responseCount: number;
  gender: string;
  ageUnit: string;
  age10: string[];
  age5: string[];
  quotaSet: string;
  quotaCount: { [key: string]: string };
  area: CREATE_REGION[];
}

export interface ProjectDetailConfig {
  simpleSurveyQuota: SimpleSurveyQuota;
  linkStatus: number;
  paymentInfo: PaymentInfo | null;
  simpleSurveyType: number;
  openStartDate: string;
  openEndDate: string;
  simpleSurveyChecklist: SimpleSurveyChecklistInfo;
}

interface ProjectDetailQuestionInfo {
  _id: string;
  title: string;
  snum: number;
  owner: string[];
  regDate: string;
  config: ProjectDetailConfig;
  data: Array<IQuestionValue>;
}

export interface TargetingInfo {
  targeting: string;
  targetingItems: string[];
}
interface ProjectDetailInfo {
  question: ProjectDetailQuestionInfo;
  targetingData: TargetingInfo;
  // TODO: type 지정
  quota: any;
}

const ADMIN = "admin";

export const projectListAPI = async (
  params: ProjectParams
): Promise<ProjectListInfo> => {
  const { data } = await ins.get<
    AxiosRequestConfig,
    AxiosResponse<ProjectListInfo>
  >(`/${ADMIN}/project`, {
    params,
  });
  return data;
};

export const projectDetailAPI = async (
  snum: string
): Promise<ProjectDetailInfo> => {
  const { data } = await ins.get<
    AxiosRequestConfig,
    AxiosResponse<ProjectDetailInfo>
  >(`/admin/project/view/${snum}`);
  return data;
};

export const projectStatusUpdateAPI = async (body: {
  snum: string;
  status: number;
}) => {
  return ins.patch(`/admin/project`, body);
};
