import { QUESTION } from "@/types/question";

const LINK_STATUS = QUESTION.LINK_STATUS;
export interface Option {
  text: string;
  value: string | QUESTION.LINK_STATUS;
  disabled?: boolean;
}

export const projectListSearch: Option[] = [
  {
    text: "프로젝트명",
    value: "TITLE",
  },
  {
    text: "UNIID",
    value: "snum",
  },
  {
    text: "진행상태",
    value: "LINK_STATUS",
  },
];

export const getLinkStatusLabel = (
  linkStatus: QUESTION.LINK_STATUS
): string => {
  switch (linkStatus) {
    case LINK_STATUS.TEST:
      return "진행 준비";
    case LINK_STATUS.REVIEW_HOLD:
      return "검수 대기";
    case LINK_STATUS.REVIEW_DONE:
      return "검수 완료";
    case LINK_STATUS.FW_START:
      return "조사 진행";
    case LINK_STATUS.FW_END:
      return "조사 종료";
    case LINK_STATUS.FW_PAUSE:
      return "일시 중단";
    case LINK_STATUS.REJECT:
      return "반려";
    case LINK_STATUS.REFUND:
      return "환불";
    default:
      return "-";
  }
};

export const getProjectListLinkStatusOptions = (): Option[] => {
  return [
    {
      text: getLinkStatusLabel(LINK_STATUS.TEST),
      value: LINK_STATUS.TEST,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.REVIEW_HOLD),
      value: LINK_STATUS.REVIEW_HOLD,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.REVIEW_DONE),
      value: LINK_STATUS.REVIEW_DONE,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_START),
      value: LINK_STATUS.FW_START,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_END),
      value: LINK_STATUS.FW_END,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_PAUSE),
      value: LINK_STATUS.FW_PAUSE,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.REJECT),
      value: LINK_STATUS.REJECT,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.REFUND),
      value: LINK_STATUS.REFUND,
    },
  ];
};

export const getProjectDetailLinkStatusOptions = (
  isPanel: boolean,
  linkStatus: QUESTION.LINK_STATUS
): Option[] => {
  const defaultOptions = [
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_START),
      value: LINK_STATUS.FW_START,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_END),
      value: LINK_STATUS.FW_END,
    },
    {
      text: getLinkStatusLabel(LINK_STATUS.FW_PAUSE),
      value: LINK_STATUS.FW_PAUSE,
    },

    {
      text: getLinkStatusLabel(LINK_STATUS.REFUND),
      value: LINK_STATUS.REFUND,
    },
  ];

  const disabled = defaultOptions
    .map(({ value }) => value)
    .includes(linkStatus);

  const options = disabled
    ? defaultOptions
    : isPanel
    ? [
        {
          text: getLinkStatusLabel(LINK_STATUS.TEST),
          value: LINK_STATUS.TEST,
        },
        {
          text: getLinkStatusLabel(LINK_STATUS.REVIEW_HOLD),
          value: LINK_STATUS.REVIEW_HOLD,
        },
        {
          text: getLinkStatusLabel(LINK_STATUS.REVIEW_DONE),
          value: LINK_STATUS.REVIEW_DONE,
        },
        ...defaultOptions,
      ]
    : [
        {
          text: getLinkStatusLabel(LINK_STATUS.TEST),
          value: LINK_STATUS.TEST,
        },
        ...defaultOptions,
      ];
  if (linkStatus !== LINK_STATUS.TEST) {
    return options.filter(({ value }) => value !== LINK_STATUS.TEST);
  }

  return options;
};
