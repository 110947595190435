<template>
  <div>
    <router-view />

    <main class="sub-layout-container mb-3">
      <div class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <font color="textTitle" size="24" weight="bold">회원 리스트</font>
        </section>
        <b-overlay
          wrap-tag="section"
          spinner-variant="primary"
          :show="loading"
          rounded="sm"
          class="sub-layout-body-container"
        >
          <div class="w-100">
            <b-row class="mt-3">
              <section class="user-list-top">
                <uni-button
                  variant="option"
                  :click="xlsxDownload"
                  class="custom-ml-12"
                >
                  <uni-font color="placeholder">다운로드</uni-font>
                </uni-button>

                <div class="d-flex align-items-center">
                  <select
                    class="my-survey-select-box"
                    size="sm"
                    v-model="searchType"
                    @change="search = ''"
                  >
                    <option
                      v-for="(option, index) of searchOptions"
                      :value="option.value"
                      :key="index"
                    >
                      <uni-font color="textBody">{{ option.text }}</uni-font>
                    </option>
                  </select>

                  <div class="d-flex" v-if="searchType === 'TYPE'">
                    <b-form-select
                      class="status-selector"
                      v-model="search"
                      :options="userTypes"
                      @change="searchSubmit"
                      size="sm"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled>
                          유형을 선택해 주세요
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="d-flex" v-else>
                    <uni-button
                      :disabeld="loading"
                      variant="icon-button"
                      :click="searchSubmit"
                      class="search-button-icon"
                    >
                      <search-icon :active="searchButtonHover" />
                    </uni-button>
                    <input
                      class="search-bar-wrapper"
                      type="text"
                      placeholder="회원 검색"
                      v-model="search"
                      @focusin="searchButtonHover = true"
                      @focusout="searchButtonHover = false"
                      @keyup.enter="searchSubmit"
                    />
                  </div>
                </div>
              </section>
              <b-col cols="12" class="table-wrap">
                <b-table-simple class="w-100 list-table" hover responsive>
                  <colgroup>
                    <col width="5%" />
                    <col width="9%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="15%" />
                    <col width="9%" />
                    <col width="15%" />
                    <col width="9%" />
                    <col width="11.11%" />
                    <col width="10%" />
                  </colgroup>
                  <b-thead>
                    <b-tr>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >NO</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >가입일</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >이름</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >USERID</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >회원ID</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >유형</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >소속</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >마케팅</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >회원등급</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >상태</uni-font
                        >
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <tbody v-if="loading">
                    <tr v-for="i in 10" :key="i">
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                    </tr>
                  </tbody>
                  <b-tbody v-else>
                    <template v-if="userList.length">
                      <b-tr
                        v-for="(user, index) in userList"
                        :key="`user-${index}`"
                        :class="[
                          'pointer',
                          isSelected(user._id) ? 'selected' : '',
                        ]"
                        @click="redirectUserDetail(user.OBJECT_ID)"
                      >
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            user.index
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            $moment(user.REGIST_DT).format("YYYY-MM-DD")
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ user.NAME }}</uni-font
                          >
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ user.OBJECT_ID }}</uni-font
                          >
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ user._id }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            convertUserType("type", user.USER_TYPE)
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            user.ENTERPRISE && user.ENTERPRISE.length > 0
                              ? user.ENTERPRISE
                              : "-"
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            user.MARKETING_AUTH ? "동의" : "미동의"
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            convertUserType("rating", user.USER_RATING)
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            `${user.USE ? "정상" : "탈퇴"}`
                          }}</uni-font>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td colspan="10">
                          <uni-font color="textTitle" size="14"
                            >검색된 회원 리스트가 없습니다.</uni-font
                          >
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="12" class="help-board-footer">
                <b-pagination-nav
                  use-router
                  align="center"
                  v-model="page"
                  :number-of-pages="numberOfPages || 1"
                  :limit="7"
                  :link-gen="linkGen"
                ></b-pagination-nav>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { IUserListInfo } from "@/interface/user/user";
import { Dictionary } from "vue-router/types/router";
import SearchIcon from "@/components/icons/common/SearchIcon.vue";

@Component({
  components: {
    SearchIcon,
  },
})
export default class UserList extends Vue {
  page = 1;
  totalRow = 0;
  numberOfPages = 1;
  limitPage = 10;
  search = "";
  searchType = "NAME";
  loading = false;
  searchButtonHover = false;
  userList: IUserListInfo[] = [];

  searchOptions = [
    { value: "NAME", text: "이름" },
    { value: "ID", text: "회원ID" },
    { value: "TYPE", text: "유형" },
    { value: "ENTERPRISE", text: "소속" },
  ];
  userTypes = [
    { value: "NORMAL", text: "개인" },
    { value: "UNIVERSITY", text: "학교" },
    { value: "COMPANY", text: "기업" },
  ];

  limitOptions = [
    { value: 10, text: "10개씩 보기" },
    { value: 20, text: "20개씩 보기" },
    { value: 30, text: "30개씩 보기" },
    { value: 40, text: "40개씩 보기" },
    { value: 50, text: "50개씩 보기" },
  ];

  redirectUserDetail(userId: string) {
    this.$router.push({
      path: `/user/${userId}`,
      query: this.$route.query,
    });
  }

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp)
      .map((entry) => entry.join("="))
      .join("&");

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue,
    });
    this.$router.push(query);
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    });
  }

  isSelected(userId: string) {
    const { id } = this.$route.params;
    return id === userId;
  }

  async created() {
    this.init();
    await this.load();
  }

  init() {
    const { page, search, searchType, limitPage } = this.$route
      .query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (limitPage) this.limitPage = +limitPage;
  }

  async load() {
    this.loading = true;
    try {
      const { data } = await this.axios.get("/admin/user/list", {
        params: {
          pageNum: this.page,
          searchType: this.searchType,
          search: this.search,
          perPage: this.limitPage,
        },
      });
      const { result, list, numberOfPages } = data;
      if (result) {
        this.userList = list;
        this.numberOfPages = numberOfPages;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }

  async xlsxDownload() {
    this.loading = true;
    try {
      const paramData = {
        pageNum: this.page,
        searchType: this.searchType,
        search: this.search,
        perPage: this.limitPage,
      };
      const data = await this.axios({
        url: "/admin/user/xlsx",
        method: "GET",
        params: paramData,
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "회원리스트.xlsx");
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  convertUserType(value: string, type: string) {
    switch (value) {
      case "type":
        return this.$common.covertUserType(type);
      case "rating":
        return this.$common.convertUserRating(type);
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrap {
  min-height: 500px;
  text-align: center;
}

.user-list-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.my-survey-select-box {
  color: $textBody;
  font-family: $nanumGothic;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  width: fit-content;
  text-align: center;
  height: 28px;
  border-radius: 17px;
  border: 1px solid $line;
  padding: 0 8px;
  &:focus {
    border-color: $point-color;
  }

  &:hover {
    cursor: pointer;
  }
}

.selected td {
  font-weight: bold;
}
.list-table td {
  font-size: 0.8rem;
}

.search-button-icon {
  position: relative;
  left: 91%;
}
</style>
