<template>
  <b-row>
    <b-col class="text-left" cols="12">
      <font size="15" color="textTitle" class="w-100">
        <b-form-group
          label="휴대폰 번호"
          label-align="left"
          label-cols="2"
          label-class="pl-3 "
        >
          <b-input-group class="d-flex">
            <input
              placeholder="ex) 01012345678"
              class="input-focus-bg input"
              maxlength="11"
              autocomplete="off"
              v-model="userPhone"
            />
            <uni-button
              :disabled="pending"
              width="84"
              height="38"
              variant="secondary-outline"
              :click="existsPhone"
              :class="phoneCheck && 'secondary-outline-active'"
              class="custom-ml-10"
            >
              <font color="placeholder" size="14">인증 요청</font>
            </uni-button>
          </b-input-group>
        </b-form-group>
      </font>
    </b-col>

    <b-col class="text-left" cols="12" v-if="authNumber.length === 6">
      <font size="15" color="textTitle" class="w-100">
        <b-form-group
          label="인증번호 입력"
          label-align="left"
          label-cols="2"
          label-class="pl-3"
        >
          <b-input-group>
            <b-form-input
              maxlength="6"
              autocomplete="off"
              class="input-focus-bg"
              v-model="authNumberConfirm"
              :state="authNumberCheck()"
            />

            <uni-button
              :disabled="authNumberCheck()"
              width="84"
              height="38"
              variant="secondary-outline"
              class="custom-ml-10"
              :class="!authNumberCheck && 'secondary-outline-active'"
              :click="auth"
            >
              <font color="placeholder" size="14">인증 확인</font>
            </uni-button>

            <b-form-invalid-feedback id="auth-number-live-feedback">
              {{ authErrorMessage }}
            </b-form-invalid-feedback>
            <b-form-valid-feedback
              id="auth-number-live-ok"
              :state="authNumberCheck()"
            >
              인증되었습니다
            </b-form-valid-feedback>
          </b-input-group>
        </b-form-group>
      </font>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component
export default class RequestAuthNumber extends Vue {
  @Prop({ required: false, default: "" }) reqUserId: string;
  @Prop({ default: false }) findPassword?: boolean;

  userPhone = "";
  userObjectId = "";
  userId = "";
  authNumberConfirm = "";
  authNumber = "";
  authFlag = false;
  authErrorMessage = "인증번호를 입력해 주세요";
  timerLimit = 181;
  timer: ReturnType<typeof setInterval>;
  pending = false;

  @Watch("timerLimit")
  watchTimerLimit() {
    if (this.timerLimit < 1) {
      this.authFlag = false;
      this.authErrorMessage = "인증시간이 만료되었습니다";
      this.timerLimit = 181;

      clearInterval(this.timer);
    }
  }

  async auth() {
    // const { data } = await this.axios.post('/user/check-auth-number', {
    //   authNumber: this.authNumber,
    // });
    // console.log(data);
    if (
      this.authNumber === this.authNumberConfirm &&
      this.authNumber.length === 6
    ) {
      this.authFlag = true;
      clearInterval(this.timer);
    } else {
      this.$common.makeToast(
        ToastMessage.WRONG_AUTH_NUMBER,
        ToastVariant.DANGER,
        this.$bvToast
      );
    }
  }

  reset() {
    this.authNumber = "";
    this.authNumberConfirm = "";
    this.authFlag = false;
    this.userPhone = "";
    this.userId = "";
    this.timerLimit = 181;
    this.authErrorMessage = "인증번호를 입력해 주세요";
    clearInterval(this.timer);
  }

  authNumberCheck(): boolean {
    return (
      this.authNumber === this.authNumberConfirm &&
      this.authNumber.length === 6 &&
      this.authFlag &&
      this.timerLimit > 0
    );
  }

  get phoneCheck(): boolean {
    return this.$common.mobileTelCheck(this.userPhone);
  }

  async existsPhone(): Promise<void> {
    try {
      if (this.findPassword && !this.$common.emailCheck(this.reqUserId)) {
        this.$common.makeToast(
          ToastMessage.NOT_MATCH_EMAIL,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      } else if (!this.phoneCheck) {
        this.$common.makeToast(
          ToastMessage.NOT_MATCH_PHONE,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      }
      this.pending = true;

      const { data } = await this.axios.get("/user/find-auth", {
        params: {
          phone: this.userPhone,
          userId: this.reqUserId,
        },
      });
      const { exist, objectId, user, authNumber } = data;

      if (exist) {
        const { _id } = user;
        this.userId = _id;
        this.userObjectId = objectId;
        this.$common.makeToast(
          ToastMessage.SEND_MAIL,
          ToastVariant.PRIMARY,
          this.$bvToast
        );
        this.authNumber = authNumber;

        this.timer = setInterval(() => {
          this.timerLimit--;
          const minute: number = Math.floor(this.timerLimit / 60);
          const second =
            minute > 0 ? this.timerLimit % (60 * minute) : this.timerLimit;
          const secondStr = String(second + 100).substring(1, 3);
          this.authErrorMessage = `[인증시간: ${minute}:${secondStr}]`;
          if (this.timerLimit <= 0) this.pending = false;
        }, 1000);
      } else {
        this.pending = false;
      }
    } catch (e) {
      console.error(e);
      this.pending = false;
    }
  }
}
</script>

<style scoped lang="scss">
.input {
  padding-left: 6px;
  border: 1px solid $line;
  border-radius: 5px;
  outline: none;
  width: calc(100% - 94px);
  height: 38px;
}
</style>
