<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import HelpCard from "@/components/login/HelpCard.vue";
import LimeLogo from "@/components/icons/LimeLogo.vue";
import { loginServiceGuideData } from "@/assets/data/loginServiceGuideData";

@Component({
  components: {
    HelpCard,
    LimeLogo,
  },
})
export default class LoginBanner extends Vue {
  get helpData() {
    return loginServiceGuideData;
  }
}
</script>

<template>
  <div class="help-card-container">
    <LimeLogo height="36" width="249" />
    <div class="carousel-wrapper">
      <carousel
        :autoplay="true"
        :loop="true"
        :navigation-enabled="true"
        :per-page="1"
        :autoplayTimeout="3000"
        navigation-next-label=""
        navigation-prev-label=""
      >
        <slide
          v-for="{ content, thumbnails, thumbnailsAlign, title } in helpData"
          :key="`help-content-card-${thumbnails}`"
        >
          <HelpCard
            :content="content"
            :thumbnails="thumbnails"
            :thumbnails-align="thumbnailsAlign"
            :title="title"
          />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.help-card-container {
  height: 747px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
  max-width: 378px;
  margin-right: 132px;
}

.carousel-wrapper {
  width: 100%;
  height: 100%;
}
</style>
