<template>
  <div class="find-account-container">
    <div class="join-form-wrapper">
      <form class="join-form">
        <section class="form-top">
          <button class="backspace-button" type="button" @click="goLogin">
            <img src="@/assets/images/icons/history-back.svg" alt="뒤로가기" />
          </button>
        </section>
        <FindAccount />
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FindAccount from "@/components/user/FindAccount.vue";

@Component({
  components: {
    FindAccount,
  },
})
export default class FindInfo extends Vue {
  userId = "";

  goLogin() {
    this.$router.push("/login");
  }
}
</script>

<style scoped lang="scss">
.find-account-container {
  width: 1200px;
  margin: 0 auto;
  .form-top {
    width: 100%;
    border-radius: 15px 15px 0 0;
    background-color: #fafafa;
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .join-form-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .join-form {
    border-radius: 5px;
    background: white;
    min-width: 800px;
    max-width: 900px;
    min-height: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  /* 뒤로가기 */
  .backspace-button {
    border: 1px solid #eeeeee;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0 1px 1px 0 #e1e1e1;
    transition: background 0.2s ease-in-out;
    width: 45px;
    height: 45px;
  }
  .backspace-button:hover {
    background: #f3f3f3;
    border-color: #f3f3f3;
    box-shadow: 0 1px 0 1px #e7e7e7;
  }
}
</style>
