<template>
  <nav class="navigation-container custom-mb-20">
    <section class="top-layout">
      <section class="d-flex">
        <router-link class="w-100 custom-mr-80" to="/list">
          <img
            src="@/assets/logos/logo-white-img-removebg-preview.png"
            class="logo"
            alt="surveyMate"
          />
        </router-link>
        <div class="nickname-container">
          <h1
            class="overlap-group1 custom-mr-10 d-flex align-items-center justify-content-center"
          >
            <font color="pointColor" weight="bold" size="15"> 관리자 </font>
          </h1>
          <font color="textSub" weight="semiBold" size="16">
            {{ userName }}
          </font>
        </div>
      </section>

      <section class="item">
        <div class="menu-items-container">
          <div class="sup-menu">
            <router-link to="/monthly-account">
              <uni-font size="15" color="textBody" weight="semiBold"
                >정산 관리</uni-font
              >
            </router-link>
            <alert-message
              @messageBoxActive="messageBox"
              :active="messageBoxActive"
              :project-list="projectList"
            />
          </div>

          <div class="menu-divider">|</div>

          <div class="sup-menu">
            <b-badge
              v-if="projectList.length > 0"
              class="position-absolute pointer"
              variant="danger"
              style="left: -5px; top: -5px"
              @click="messageBoxActive = !messageBoxActive"
            >
              {{ projectCount }}
            </b-badge>
            <router-link to="/list">
              <uni-font size="15" color="textBody" weight="semiBold"
                >프로젝트 관리</uni-font
              >
            </router-link>
            <alert-message
              @messageBoxActive="messageBox"
              :active="messageBoxActive"
              :project-list="projectList"
            />
          </div>

          <div class="menu-divider">|</div>

          <div class="sup-menu">
            <b-badge
              v-if="existsNew"
              class="position-absolute"
              variant="danger"
              style="left: 7px; top: -5px"
            >
              N
            </b-badge>
            <uni-font
              size="15"
              color="textBody"
              weight="semiBold"
              style="cursor: default"
              >회원 관리</uni-font
            >
            <div class="sub-menu">
              <div class="d-flex justify-content-center">
                <span v-if="userCount > 0" class="badge">N</span>
                <router-link to="/user">
                  <uni-font color="textBody" size="14" weight="medium">
                    회원 리스트
                  </uni-font>
                </router-link>
              </div>
              <div class="d-flex justify-content-center">
                <span v-if="questionCount > 0" class="badge">N</span>
                <router-link to="/question">
                  <uni-font color="textBody" size="14" weight="medium">
                    1:1 문의
                  </uni-font>
                </router-link>
              </div>
            </div>
          </div>
          <div class="menu-divider">|</div>
          <!--          <div class="sup-menu">-->
          <!--            <router-link to="/announcement">-->
          <!--              <uni-font size="15" color="textBody" weight="semiBold"-->
          <!--                >공지사항 관리</uni-font-->
          <!--              >-->
          <!--            </router-link>-->
          <!--          </div>-->
          <div
            class="sup-menu d-flex align-items-center justify-content-center"
          >
            <uni-button variant="icon-button" :click="logout">
              <uni-font color="textSub" size="14" weight="medium"
                >로그아웃</uni-font
              >
            </uni-button>
          </div>
        </div>
      </section>
    </section>
    <logout-modal />
  </nav>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LogoutModal from "@/components/modal/LogoutModal.vue";
import AlertMessage from "@/components/AlertMessage.vue";

@Component({
  components: {
    AlertMessage,
    LogoutModal,
  },
})
export default class TopNav extends Vue {
  messageBoxActive = false;

  logout() {
    this.$bvModal.show("logout-modal");
  }

  messageBox(payload: boolean) {
    this.messageBoxActive = payload;
  }

  async created() {
    await this.$store.dispatch("socketStore/emitAdminHeaderInfo");
  }

  get userName() {
    return this.$store.getters["userName"];
  }

  get userId() {
    const id = this.$store.getters["userId"];
    return id.toLowerCase();
  }

  get projectList() {
    return this.$store.getters["socketStore/projectList"];
  }

  get projectCount() {
    return this.$store.getters["socketStore/projectCount"];
  }

  get userCount() {
    return this.$store.getters["socketStore/userCount"];
  }

  get questionCount() {
    return this.$store.getters["socketStore/questionCount"];
  }

  get existsNew() {
    return this.userCount || this.questionCount;
  }
}
</script>

<style scoped lang="scss">
hr {
  margin: 0;
}
.logo {
  height: 35px;
}
.dropdown-menu {
  padding: 0 !important;
}
/* 드랍다운 메뉴 리스트 */
.dropdown-menu-list {
  display: none;
  width: 75px;
}

.navigation-container {
  background-color: var(--white);
  height: 52px;
  width: 100%;
  margin: auto;
  box-shadow: 0 0 10px 0 #dddddd;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlap-group1 {
  background-color: #35b6e71a;
  border-radius: 26px;
  display: flex;
  height: 26px;
  min-width: 138px;
  padding: 4px 12px;
  width: fit-content;
}

.nickname-container {
  display: flex;
  align-items: center;
  min-width: 400px;
}

/* 메뉴 리스트 */
.menu-items-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
}

.top-layout {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.menu-divider {
  color: #bcbcbc;
}

.sup-menu {
  width: 120px;
  position: relative;
  text-align: center;

  &:hover {
    .sub-menu {
      opacity: 1;
      height: 90px;
      padding: 20px 20px 0;
      transition: 0.3s;

      * {
        opacity: 1;
        animation-name: fade;
        animation-duration: 0.3s;
      }
    }
  }
}

.sub-menu {
  position: absolute;
  background-color: white;
  width: 120px;
  padding: 0;
  height: 0;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;

  * {
    opacity: 0;
    transition: 0.3s;
  }

  .badge {
    position: absolute;
    font-size: 8px;
    left: 10px;
    background-color: #fd6443;
    color: white;
  }
}

.message-box {
  position: absolute;
  background-color: #fdfdfd;
  width: 440px;
  max-height: 250px;
  height: auto;
  border: 1px solid $line;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;

  .message_wrapper {
    width: 100%;
    text-align: left;
    padding: 4px 2px;
    border-bottom: 1px solid $line;
    cursor: pointer;

    &:hover {
      background-color: #f1f1f1;
    }

    .message {
      font-family: $nanumGothic;
      font-size: 14px;
      width: 100%;
      padding: 0 8px;
      color: $textBody;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
