<template>
  <b-table-simple class="w-100 text-center" hover responsive>
    <colgroup>
      <col width="10%" />
      <col width="50%" />
      <col width="15%" />
      <col width="10%" />
      <col width="15%" />
    </colgroup>
    <b-thead>
      <b-tr>
        <b-th class="nanumgothic-normal-fuscous-gray-16px">유형</b-th>
        <b-th class="nanumgothic-normal-fuscous-gray-16px">제목</b-th>
        <b-th class="nanumgothic-normal-fuscous-gray-16px">문의메일</b-th>
        <b-th class="nanumgothic-normal-fuscous-gray-16px">등록일</b-th>
        <b-th class="nanumgothic-normal-fuscous-gray-16px">답변여부</b-th>
      </b-tr>
    </b-thead>
    <tbody v-if="loading">
      <tr v-for="i in 10" :key="i">
        <th class="px-3"><b-skeleton /></th>
        <th class="px-3"><b-skeleton /></th>
        <th class="px-3"><b-skeleton /></th>
        <th class="px-3"><b-skeleton /></th>
        <th class="px-3"><b-skeleton /></th>
      </tr>
    </tbody>
    <b-tbody v-else>
      <template v-if="helpList.length > 0">
        <b-tr
          v-for="(list, index) in helpList"
          :key="`list-${index}`"
          class="pointer"
          @click="questionView(index)"
        >
          <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
            <b-badge variant="primary" class="ml-3">{{
              list.TYPE || "-"
            }}</b-badge>
          </b-td>
          <b-td class="nanumgothic-normal-granite-gray-16px"
            ><span class="help-items">{{ list.TITLE }}</span></b-td
          >
          <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
            <small>{{ list.USER_ID.toLowerCase() }}</small></b-td
          >
          <b-td
            class="payment-table-text nanumgothic-normal-granite-gray-16px"
            >{{ list.REG_DATE }}</b-td
          >
          <b-td class="payment-table-text nanumgothic-normal-granite-gray-16px">
            <b-badge :variant="statusColor(list.STATUS)">
              {{ list.STATUS }}
            </b-badge>
          </b-td>
        </b-tr>
      </template>
      <template v-else>
        <b-tr>
          <b-td
            colspan="5"
            class="text-center payment-table-text nanumgothic-normal-granite-gray-16px"
          >
            등록된 1:1문의가 없습니다
          </b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HelpList extends Vue {
  @Prop() loading;
  @Prop() helpList;

  statusColor(status: string): string {
    if (status === "확인중") return "warning";
    if (status === "답변완료") return "success";
    return "info";
  }

  questionView(index: number) {
    this.$emit("view", index);
  }
}
</script>

<style scoped>
.payment-table {
  width: 100%;
  min-height: 130px;
}

.payment-table-text {
  letter-spacing: -0.48px;
  min-height: 18px;
  min-width: 45px;
  text-align: center;
  font-weight: 400;
}
</style>
