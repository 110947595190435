<template>
  <div>
    <router-view />

    <main class="sub-layout-container mb-3">
      <div class="sub-layout-top-container">
        <section class="sub-items-title-wrapper">
          <font color="textTitle" size="24" weight="bold"
            >정산 관리 리스트</font
          >
        </section>
        <b-overlay
          wrap-tag="section"
          spinner-variant="primary"
          :show="loading"
          rounded="sm"
          class="sub-layout-body-container"
        >
          <div class="w-100">
            <b-row class="mt-3">
              <section class="user-list-top">
                <div class="pl-3 d-flex align-items-center">
                  <div class="d-flex">
                    <b-form-datepicker
                      id="example-datepickerStart"
                      class="date-picker"
                      v-model="searchDateStart"
                      size="sm"
                      width="400"
                      :max="searchDateEnd"
                    />
                    <div class="mx-1">~</div>
                    <b-form-datepicker
                      id="example-datepickerEnd"
                      class="date-picker"
                      v-model="searchDateEnd"
                      size="sm"
                      :min="searchDateStart"
                    />
                  </div>
                  <div class="d-flex">
                    <uni-button
                      variant="option"
                      :click="searchList"
                      class="custom-ml-12"
                    >
                      <uni-font color="placeholder">검색</uni-font>
                    </uni-button>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <uni-button
                    variant="option"
                    :click="xlsxDownload"
                    class="custom-ml-12"
                    v-if="accountList.length > 0"
                  >
                    <uni-font color="placeholder">다운로드</uni-font>
                  </uni-button>
                </div>
              </section>
              <b-col cols="12" class="table-wrap">
                <b-table-simple class="w-100 list-table" hover responsive>
                  <colgroup>
                    <col width="8%" />
                    <col width="15%" />
                    <col width="7%" />
                    <col width="20%" />
                    <col width="10%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="10%" />
                  </colgroup>
                  <b-thead>
                    <b-tr>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >프로젝트ID</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >회원ID</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >유형</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >프로젝트명</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >결제유형</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >결제일</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >공급가</uni-font
                        >
                      </b-th>
                      <b-th>
                        <uni-font color="textTitle" size="14" weight="bold"
                          >정산수수료</uni-font
                        >
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <tbody v-if="loading">
                    <tr v-for="i in 10" :key="i">
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                      <th class="pl-3 pr-3"><b-skeleton /></th>
                    </tr>
                  </tbody>
                  <b-tbody v-else>
                    <template v-if="accountList.length">
                      <b-tr
                        v-for="(account, index) in accountList"
                        :key="`user-${index}`"
                      >
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            account.SNUM
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <div
                            v-for="(owner, ownIdx) in account.ROLE.OWNER"
                            :key="ownIdx"
                          >
                            {{
                              account.ROLE.OWNER.length > 1
                                ? owner !== "SI***********"
                                  ? owner
                                  : ""
                                : owner
                            }}
                          </div>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{
                              account.CONFIG.SIMPLE_SURVEY_TYPE === 1
                                ? "패널"
                                : "리스트"
                            }}</uni-font
                          >
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{ account.TITLE || "제목 없음" }}</uni-font
                          >
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">
                            {{
                              account.CONFIG.PAYMENT_INFO
                                ? account.CONFIG.PAYMENT_INFO.data.method
                                : "-"
                            }}
                          </uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            account.CONFIG.PAYMENT_INFO
                              ? account.CONFIG.PAYMENT_INFO.data.purchased_at
                              : "-"
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            account.CONFIG.PAYMENT_INFO
                              ? numberComma(
                                  account.CONFIG.PAYMENT_INFO.data.supply_price
                                )
                              : "-"
                          }}</uni-font>
                        </b-td>
                        <b-td>
                          <uni-font color="textBody" size="13">{{
                            account.CONFIG.PAYMENT_INFO
                              ? numberComma(
                                  account.CONFIG.PAYMENT_INFO.data.charge
                                )
                              : "-"
                          }}</uni-font>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td colspan="8">
                          <uni-font color="textTitle" size="14"
                            >검색된 정산 리스트가 없습니다.</uni-font
                          >
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="12" class="help-board-footer">
                <b-pagination-nav
                  use-router
                  align="center"
                  v-model="page"
                  :number-of-pages="numberOfPages || 1"
                  :limit="7"
                  :link-gen="linkGen"
                ></b-pagination-nav>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import SearchIcon from "@/components/icons/common/SearchIcon.vue";
import moment from "moment";

interface OWNER {
  OWNER: string[];
}

interface IProjectList {
  ROLE: OWNER;
  CONFIG: any;
  SNUM: number;
  TITLE: string;
  isUse: boolean;
  regDate: Date;
  _id: string;
  STATUS: string;
}

@Component({
  components: {
    SearchIcon,
  },
})
export default class MonthlyAccountList extends Vue {
  page = 1;
  totalRow = 0;
  numberOfPages = 1;
  limitPage = 10;
  search = "";
  searchType = "NAME";
  searchDateStart = "";
  searchDateEnd = "";
  loading = false;
  searchButtonHover = false;
  accountList: IProjectList[] = [];

  redirectUserDetail(userId: string) {
    this.$router.push({
      path: `/admin/user/${userId}`,
      query: this.$route.query,
    });
  }

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp)
      .map((entry) => entry.join("="))
      .join("&");

    return `?${query}`;
  }

  async created() {
    this.init();
    await this.load();
  }

  init() {
    const {
      page,
      search,
      searchType,
      limitPage,
      searchDateStart,
      searchDateEnd,
    } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (limitPage) this.limitPage = +limitPage;
    if (searchDateStart) this.searchDateStart = searchDateStart;
    if (searchDateEnd) this.searchDateEnd = searchDateEnd;
  }

  async load() {
    this.loading = true;
    try {
      const { data } = await this.axios.get("/admin/project/account/list", {
        params: {
          pageNum: this.page,
          searchType: this.searchType,
          search: this.search,
          perPage: this.limitPage,
          searchDateStart: this.searchDateStart,
          searchDateEnd: this.searchDateEnd,
        },
      });
      const { result, list, numberOfPages } = data;
      if (result) {
        this.accountList = list.map((v) => {
          if (v.CONFIG.PAYMENT_INFO) {
            v.CONFIG.PAYMENT_INFO.data.purchased_at = moment(
              v.CONFIG.PAYMENT_INFO.data.purchased_at
            ).format("YYYY-MM-DD");
            v.CONFIG.PAYMENT_INFO.data.supply_price = Math.round(
              v.CONFIG.PAYMENT_INFO.data.price / 1.1
            );
            v.CONFIG.PAYMENT_INFO.data.charge = Math.round(
              v.CONFIG.PAYMENT_INFO.data.supply_price * 0.15
            );
          }
          return v;
        });
        this.numberOfPages = numberOfPages;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }

  searchList() {
    const query = this.makeQuery({
      page: 1,
      searchDateStart: this.searchDateStart,
      searchDateEnd: this.searchDateEnd,
    });
    this.$router.push(query).catch(() => ({}));
  }

  async xlsxDownload() {
    this.loading = true;
    try {
      const paramData = {
        pageNum: this.page,
        searchType: this.searchType,
        search: this.search,
        perPage: this.limitPage,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      };
      const data = await this.axios({
        url: "/admin/project/account/xlsx",
        method: "GET",
        params: paramData,
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "정산리스트.xlsx");
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrap {
  min-height: 500px;
  text-align: center;
}

.user-list-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
}

.date-picker {
  width: 210px;
  text-align: center;
}

.help-board-footer {
  height: 50px;
  text-align: center;
}

.my-survey-select-box {
  color: $textBody;
  font-family: $nanumGothic;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  width: fit-content;
  text-align: center;
  height: 28px;
  border-radius: 17px;
  border: 1px solid $line;
  padding: 0 8px;
  &:focus {
    border-color: $point-color;
  }

  &:hover {
    cursor: pointer;
  }
}

.selected td {
  font-weight: bold;
}
.list-table td {
  font-size: 0.8rem;
}

.search-button-icon {
  position: relative;
  left: 91%;
}
</style>
