<template>
  <div>
    <main class="sub-layout-container mb-3">
      <article
        class="sub-layout-top-container"
        style="min-width: 1200px; max-width: 1200px"
      >
        <section class="sub-items-title-wrapper">
          <font color="textTitle" size="24" weight="bold"
            >프로젝트 상세정보</font
          >
        </section>
        <b-overlay
          spinner-variant="primary"
          :show="loading"
          rounded="sm"
          class="form"
        >
          <article class="detail">
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  의뢰자 ID</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">
                  {{ getProjectOwner }}
                </font>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  설문 유형</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">
                  {{ config?.simpleSurveyType === 1 ? "패널" : "리스트" }}
                </font>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  프로젝트 번호</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">
                  {{ snum }}
                </font>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  프로젝트 명</font
                >
              </div>
              <div class="detail-content">
                <div class="d-flex align-items-center justify-content-between">
                  <!--                  <font class="custom-mr-16" color="textBody" size="14">-->
                  <!--                    <a-->
                  <!--                      target="_blank"-->
                  <!--                      :href="`${host}/project/make/${this.snum}/${this.token}`"-->
                  <!--                    >-->
                  <!--                      {{ title || "제목 없음" }}-->
                  <!--                    </a>-->
                  <!--                  </font>-->
                  <font class="custom-mr-16" color="textBody" size="14">
                    {{ title || "제목 없음" }}
                  </font>
                  <div class="d-flex">
                    <uni-button
                      width="80"
                      height="30"
                      variant="secondary-outline"
                      :click="showSurveyPreview"
                      class="custom-mr-8"
                    >
                      <font color="textSub" size="14">미리보기</font>
                    </uni-button>
                    <uni-button
                      width="80"
                      height="30"
                      variant="secondary-outline"
                      :click="resultFunc"
                      class="custom-mr-8"
                    >
                      <font color="textSub" size="14">결과보기</font>
                    </uni-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  대상자 정보</font
                >
              </div>
              <div class="detail-content">
                <ProjectViewQuota :quota="quota" :isPanel="isPanel" />
              </div>
            </div>
            <div
              v-if="
                isPanel &&
                targetingData?.targeting &&
                targetingData?.targetingItems.length
              "
              class="detail-row"
            >
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  거래 데이터 활용</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14"
                  >{{
                    `${
                      targetingData.targeting
                    } (${targetingData.targetingItems.join(", ")})`
                  }}
                </font>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  문항수</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">{{ qData.length }}개</font>
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  조사기간</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14"
                  >{{ config?.openStartDate }} ~ {{ config?.openEndDate }}</font
                >
              </div>
            </div>
            <div v-if="resultShow" class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  진행현황</font
                >
              </div>
              <div class="detail-content">
                <QuotaParticipation
                  :survey-participation="surveyParticipation"
                  :limit-count="limitCount"
                  :project-id="snum"
                  :loading="loading"
                  :componentType="'result'"
                  class="custom-mt-40"
                />
                <hr />
                <Sampling
                  :snum="snum"
                  :loading="loading"
                  :inProgress="linkStatus === LINK_STATUS.FW_START"
                  :isPanel="config?.simpleSurveyType === 1"
                />
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  진행 상태</font
                >
              </div>
              <div v-if="!isReject" class="detail-content">
                <div class="d-flex">
                  <b-form-select
                    size="sm"
                    v-model="linkStatus"
                    :options="statusOptions"
                    @change="changeStatus"
                    style="width: 20%"
                  />
                  <uni-button
                    class="custom-ml-16"
                    width="80"
                    height="31"
                    :click="statusSave"
                  >
                    <font :color="'white'" size="14" weight="medium">저장</font>
                  </uni-button>
                </div>
              </div>
              <div v-else class="detail-content">
                <font color="textBody" size="14">
                  {{ getLinkStatusLabel(linkStatus) }}
                </font>
              </div>
            </div>
            <div v-if="isPaid" class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  결제 유형</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">
                  {{
                    config?.PAYMENT_INFO
                      ? `${config?.paymentInfo.data.method} 결제`
                      : ""
                  }}</font
                >
              </div>
            </div>
            <div v-if="isPaid" class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  결제금액</font
                >
              </div>
              <div class="detail-content">
                <font color="textBody" size="14">
                  {{
                    config?.paymentInfo
                      ? numberComma(config?.PAYMENT_INFO.data.price)
                      : 0
                  }}
                  원</font
                >
              </div>
            </div>
            <div v-if="isPaid" class="detail-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  결제 진행 상태</font
                >
              </div>
              <div class="detail-content">
                <b-form-select
                  size="sm"
                  v-model="linkStatus"
                  :options="statusOptions"
                  @change="statusSave"
                  :disabled="true"
                  style="width: 20%"
                />
                <b-button-group class="ml-3">
                  <uni-button
                    width="80"
                    height="31"
                    variant="red"
                    :click="() => this.$bvModal.show('modal-1')"
                    :disabled="!isPaid || refunded"
                    class="custom-mr-16"
                  >
                    <font
                      :color="!isPaid || refunded ? 'placeholder' : 'white'"
                      size="14"
                      weight="medium"
                      >결제 취소</font
                    >
                  </uni-button>
                  <uni-button
                    size="sm"
                    width="100"
                    height="31"
                    variant="secondary-outline"
                    :click="showReceipt"
                    :disabled="!isPaid"
                  >
                    <font color="placeholder" size="14" weight="medium"
                      >영수증 출력</font
                    >
                  </uni-button>
                </b-button-group>
              </div>
            </div>
            <div v-if="isReviewHold || isReject" class="detail-row last-row">
              <div class="detail-title">
                <font color="textTitle" weight="semiBold" size="15">
                  검수 항목</font
                >
              </div>
              <div class="detail-content">
                <ProjectViewCheckList
                  :snum="snum"
                  :simpleSurveyChecklist="config.simpleSurveyChecklist"
                  :owner="getProjectOwner"
                  :isRefunded="refunded"
                  :isTest="isTest"
                  :isReject="isReject"
                  @checkListActive="checkListActive"
                />
              </div>
            </div>
          </article>
        </b-overlay>
        <div class="d-flex justify-content-end w-100 custom-pt-20">
          <uni-button width="80" height="31" :click="onClickProjectList">
            <font :color="'white'" size="14" weight="medium">목록</font>
          </uni-button>
        </div>
      </article>
    </main>
    <b-modal
      centered
      size="xl"
      hide-footer
      v-model="preview"
      @hidden="hideSurveyPreview"
      body-class="p-0 h-100"
    >
      <b-overlay
        class="h-100"
        spinner-variant="primary"
        size="sm"
        :show="previewLoading"
      >
        <iframe
          :src="`${host}/project/preview/${this.snum}/${this.token}`"
          width="100%"
          height="1000"
          @load="previewLoading = false"
        >
        </iframe>
      </b-overlay>
    </b-modal>

    <b-modal id="modal-1" title="결제 취소" hide-footer>
      <p class="my-4">userId: {{ getProjectOwner }}</p>
      <p class="my-4">
        취소금액:
        {{
          config?.paymentInfo ? numberComma(config.paymentInfo.data.price) : 0
        }}
      </p>
      <b-form-group
        id="input-group-1"
        label="취소자 명:"
        label-for="input-name"
        description="취소자 명을 입력해주세요."
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          type="text"
          required
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="취소 사유:"
        label-for="input-name"
        description="취소사유를 입력하여주세요."
      >
        <b-form-input
          id="input-name"
          v-model="form.reason"
          type="text"
          placeholder="입력하여 주세요."
          required
        />
      </b-form-group>
      <b-button variant="danger" v-b-modal.modal-1 @click="cancelReceipt()"
        >결제 취소</b-button
      >
    </b-modal>
    <make-template-modal
      :template-title="title"
      :template-config="config"
      :template-info="data"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProjectViewQuota from "@/components/board/ProjectViewQuota.vue";
import ProjectViewCheckList from "@/components/board/ProjectViewCheckList.vue";
import ProjectViewSkeleton from "@/components/board/ProjectViewSkeleton.vue";
import moment from "moment";
import QuotaParticipation from "@/components/board/QuotaParticipation.vue";
import Sampling from "@/components/board/Sampling.vue";
import { QUESTION } from "@/types/question";
import MakeTemplateModal from "@/components/modal/MakeTemplateModal.vue";
import { IQuestionValue } from "@/interface/admin/question";
import {
  getLinkStatusLabel,
  getProjectDetailLinkStatusOptions,
  Option,
} from "@/libs";
import {
  projectDetailAPI,
  ProjectDetailConfig,
  projectStatusUpdateAPI,
  SimpleSurveyChecklistInfo,
  TargetingInfo,
} from "@/api";

@Component({
  methods: { getLinkStatusLabel },
  computed: {
    LINK_STATUS() {
      return QUESTION.LINK_STATUS;
    },
  },
  components: {
    MakeTemplateModal,
    ProjectViewQuota,
    ProjectViewCheckList,
    ProjectViewSkeleton,
    QuotaParticipation,
    Sampling,
  },
})
export default class ProjectView extends Vue {
  token = this.$store.getters.token;
  data: Array<IQuestionValue> = [];
  config: ProjectDetailConfig = {
    simpleSurveyQuota: {
      responseCount: 0,
      gender: "",
      ageUnit: "",
      age10: [],
      age5: [],
      quotaSet: "",
      quotaCount: {},
      area: [],
    },
    paymentInfo: null,
    simpleSurveyChecklist: {
      _id: "",
      privateInfo: false,
      sensitiveInfo: false,
      political: false,
      comment: "",
    },
    linkStatus: 0,
    simpleSurveyType: 0,
    openStartDate: "",
    openEndDate: "",
  };
  isReviewHold = false;
  isReject = false;
  isPanel = false;
  isReview = false;
  isTest = false;
  refunded = false;
  loading = false;
  isPaid = false;
  title = "";
  snum = "";
  owner = "";
  quota: any = {};
  qData: any = [];
  linkStatus = 0;
  regDate = "";
  form: { name: string; reason: string } = { name: "", reason: "" };
  preview = false;
  previewLoading = false;
  resultShow = false;
  limitCount = 0;
  statusOptions: Option[] = [];
  targetingData: TargetingInfo = {
    targeting: "",
    targetingItems: [],
  };
  surveyParticipation = {
    ALL: { NAME: "ALL", COUNT: 0 },
    BAD: { NAME: "BAD", COUNT: 0 },
    COMPLETE: { NAME: "COMPLETE", COUNT: 0 },
    DROP: { NAME: "DROP", COUNT: 0 },
    OVER: { NAME: "OVER", COUNT: 0 },
    OUT: { NAME: "OUT", COUNT: 0 },
  };

  created() {
    this.load();
  }

  openRegistrationModal() {
    this.$bvModal.show("new-template-registration-modal");
  }

  get getProjectOwner() {
    return this.owner.split(",")[0];
  }

  get isProd() {
    return process.env.NODE_ENV === "production";
  }

  get isStaging() {
    return process.env.NODE_ENV === "staging";
  }

  get host() {
    return this.isProd
      ? process.env.VUE_APP_HOST_FRONT
      : this.isStaging
      ? "https://dev.surveymate.co.kr"
      : `http://localhost:8080`;
  }

  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }
  async load() {
    this.loading = true;
    try {
      const { id } = this.$route.params;
      const {
        question,
        quota: projectQuota,
        targetingData,
      } = await projectDetailAPI(id);
      const { title, config, owner, regDate, data, snum } = question;
      const { simpleSurveyQuota, linkStatus, paymentInfo, simpleSurveyType } =
        config;
      this.targetingData = targetingData;
      this.data = data;
      this.title = title;
      this.owner = owner.join(", ");
      this.snum = snum.toString();
      this.config = config;
      this.quota = simpleSurveyQuota;
      this.linkStatus = linkStatus;
      this.isReviewHold = linkStatus === QUESTION.LINK_STATUS.REVIEW_HOLD;
      this.isTest = linkStatus === QUESTION.LINK_STATUS.TEST;
      this.isReview =
        linkStatus === QUESTION.LINK_STATUS.REVIEW_HOLD ||
        linkStatus === QUESTION.LINK_STATUS.REVIEW_DONE;
      this.isReject = linkStatus === QUESTION.LINK_STATUS.REJECT;
      this.isPaid = paymentInfo ? true : false;
      this.refunded = linkStatus === 4;
      this.regDate = this.dateFormat(new Date(regDate));
      this.isPanel = simpleSurveyType === 1;
      this.statusOptions = getProjectDetailLinkStatusOptions(
        this.isPanel,
        linkStatus
      );
      this.qData = data.filter((question) => {
        const { NAME } = question;
        return QUESTION.HIDE_QUESTIONS.indexOf(NAME) === -1;
      });
      this.limitCount = 0;

      const quotaArray = data.filter(
        (item) => item.TYPE === QUESTION.QUESTION_TYPES.QUOTA
      );
      quotaArray.forEach((data) => {
        const { QUOTA } = data;
        let sum = 0;
        for (const row in QUOTA) {
          sum += Number(QUOTA[row]["quota"]);
        }
        this.limitCount += sum;
      });
      this.surveyParticipation.ALL.COUNT = projectQuota.all
        ? projectQuota.all
        : 0;
      this.surveyParticipation.COMPLETE.COUNT = projectQuota.complete
        ? projectQuota.complete
        : 0;
      this.surveyParticipation.DROP.COUNT = projectQuota.drop
        ? projectQuota.drop
        : 0;
      this.surveyParticipation.OVER.COUNT = projectQuota.over
        ? projectQuota.over
        : 0;
      this.surveyParticipation.OUT.COUNT = projectQuota.out
        ? projectQuota.out
        : 0;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  showReceipt() {
    if (this.config && this.config?.paymentInfo) {
      const url = this.config.paymentInfo.data.receipt_url;
      window.open(
        url,
        "",
        "height=955, width= 700, top=150, left=150, toolbar=no, resizeable=no"
      );
    }
  }

  changeStatus() {
    if (this.linkStatus !== QUESTION.LINK_STATUS.REVIEW_HOLD) {
      this.config.simpleSurveyChecklist = {
        political: false,
        privateInfo: false,
        sensitiveInfo: false,
        comment: "",
      };
    }
  }

  async cancelReceipt() {
    const data = this.config?.paymentInfo?.data;
    if (!data) return;
    const sendData = {
      snum: this.snum,
      receipt_id: data.receipt_id,
      price: data.price,
      tax_free: data.taxFree,
      name: this.form.name,
      reason: this.form.reason,
      userId: this.$store.state.userId,
    };
    const result = await this.axios.post(
      "/admin/project/paymentCancel",
      sendData
    );
    if (result) {
      this.$toast.success("결제 취소 완료");
      this.$bvModal.hide("modal-1");
    }
    await this.load();
  }

  dateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  onClickProjectList() {
    this.$router.push("/list");
  }

  async statusSave() {
    const confirm = window.confirm("진행 상태를 변경하시겠습니까?");
    if (confirm) {
      try {
        const body = {
          snum: this.snum,
          status: this.linkStatus,
        };
        const { status } = await projectStatusUpdateAPI(body);
        if (status === 200) {
          this.statusOptions = getProjectDetailLinkStatusOptions(
            this.isPanel,
            this.linkStatus
          );
          this.isTest = this.linkStatus === QUESTION.LINK_STATUS.TEST;
          const linkStatusLabel = getLinkStatusLabel(this.linkStatus);
          await this.unisurveyAlertUpdate();
          this.$toast.success(
            `SNUM: ${this.snum} 의 상태가 ${linkStatusLabel} (으)로 변경되었습니다.`
          );
          await this.load();
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      this.linkStatus = this.config.linkStatus;
    }
  }

  /**
   * @description: 설문 미리보기
   * 검수 대기 설문을 클랙했을 때, 해당 설문은 검수 중으로 상태 변경
   * @private
   */
  private async showSurveyPreview() {
    this.preview = true;
    this.previewLoading = true;
    try {
      if (this.linkStatus === 201) {
        // await this.axios.put(`/admin/project/status/inspection/${this.snum}`);
        await this.unisurveyAlertUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  }
  async checkListActive(checkList: SimpleSurveyChecklistInfo) {
    try {
      if (!checkList) return;
      const { privateInfo, sensitiveInfo, political, comment } = checkList;
      const sendData = {
        snum: this.snum,
        privateInfo,
        sensitiveInfo,
        political,
        comment,
      };
      await this.axios.post("/admin/project/check-list", sendData);
      await this.$store.dispatch("socketStore/unisurveyAlertUpdate", {
        SNUM: this.snum,
        userId: this.owner,
        ALERT_CHECK: false,
      });
      this.$toast.success(
        `SNUM: ${this.snum} 의 ${
          this.isReject
            ? "반려내용이 수정되었습니다."
            : "상태가 반려 (으)로 변경되었습니다."
        }`
      );
      await this.load();
    } catch (e) {
      console.error(e);
    }
  }
  async unisurveyAlertUpdate() {
    await this.$store.dispatch("socketStore/unisurveyAlertUpdate", {
      SNUM: this.snum,
      userId: this.getProjectOwner,
      ALERT_CHECK: false,
    });
    await this.$store.dispatch("socketStore/emitAdminHeaderInfo");
  }

  private async hideSurveyPreview() {
    this.preview = false;
    // await this.load();
    await this.unisurveyAlertUpdate();
  }

  private resultFunc() {
    this.resultShow = !this.resultShow;
  }
}
</script>
<style scoped lang="scss">
.form {
  align-items: flex-start;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: 20px 30px;
  min-width: 1200px;
  max-width: 1200px;
  width: 80%;
  margin: 0;

  .detail {
    width: 100%;

    .detail-row {
      display: flex;
      border-top: 1px solid #dee2e6;

      .detail-title,
      .detail-content {
        padding: 8px 3rem;
      }

      .detail-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        background-color: #eeeeee;
      }

      .detail-content {
        width: 75%;
      }
    }

    .last-row {
      border-bottom: 1px solid #dee2e6;
    }
  }

  a {
    text-decoration: none;
  }

  .table-col {
    background-color: #eeeeee;
  }
}

.table th {
  vertical-align: middle;
}
</style>
