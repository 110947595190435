<template>
  <div class="login-box">
    <form v-if="!passwordConfirm" class="login-form" @submit.prevent="login">
      <font class="w-100 custom-mb-40" size="24" weight="bold"
        >Admin Login</font
      >
      <div class="w-100">
        <font class="w-100 d-flex" size="14" type="square" weight="semiBold">
          Email
        </font>
        <label :class="idFocus && idActive" class="login-input-wrapper">
          <id-icon :id-focus="idFocus" class="custom-ml-10" />
          <input
            ref="idInput"
            v-model="loginInfo.userId"
            :class="idFocus && idFocusInput"
            class="text_label login_placeholder"
            placeholder="이메일을 입력해주세요."
            tabindex="1"
            type="text"
            @input="resetFail"
            @focusin="idFocus = true"
            @focusout="idFocus = false"
          />
        </label>
      </div>
      <div class="w-100 custom-mt-12">
        <font class="w-100 d-flex" size="14" type="square" weight="semiBold">
          Password
        </font>
        <label
          :class="passwordFocus && passwordActive"
          class="login-input-wrapper"
        >
          <password-icon :password-focus="passwordFocus" class="custom-ml-10" />
          <input
            ref="passwordInput"
            v-model="loginInfo.userPwd"
            :class="passwordFocus && pwFocusInput"
            type="password"
            autocomplete="off"
            class="text_label login_placeholder"
            min="8"
            placeholder="비밀번호를 입력해주세요."
            tabindex="1"
            @input="resetFail"
            @focusin="passwordFocus = true"
            @focusout="passwordFocus = false"
          />
        </label>
      </div>
      <div
        v-if="failCount && failCount < 5"
        class="d-flex flex-column text-center custom-pt-10"
      >
        <font color="red" weight="semiBold"
          >이메일과 비밀번호를 다시 확인해 주세요. ({{ failCount }}회
          오류)</font
        >
        <font color="red" weight="semiBold"
          >연속 5회 이상 로그인에 실패시 해당 계정은 잠깁니다.</font
        >
      </div>
      <div
        v-if="failCount > 4 || accountLock"
        class="d-flex flex-column text-center custom-pt-10"
      >
        <font color="red" weight="semiBold"
          >연속 5회 이상 로그인에 실패하였습니다.</font
        >
        <font color="red" weight="semiBold"
          >ID/PW 찾기로 계정 정보를 재설정 해주세요.</font
        >
      </div>
      <div class="login-button-wrapper">
        <uni-button
          :disabled="failCount > 4 || accountLock || isLoading"
          class="m-auto"
          type="submit"
          variant="black"
          @keydown.enter="login"
        >
          <font color="white" size="18" type="square" weight="semiBold"
            >로그인</font
          >
        </uni-button>
        <div class="d-flex justify-content-end custom-pt-10">
          <router-link to="/users/findPwd">
            <font size="14" type="square"> ID/PW 찾기 </font>
          </router-link>
        </div>
      </div>
    </form>
    <form v-else class="login-form" @submit.prevent="mfaLogin">
      <div class="w-100">
        <font-awesome-icon
          @click="back"
          class="pointer"
          :icon="['fas', 'arrow-left']"
        />
      </div>
      <div class="custom-mt-12 w-100 d-flex align-items-end">
        <div>
          <font class="w-100 d-flex" size="14" type="square" weight="semiBold">
            인증번호
          </font>
          <label class="login-input-wrapper">
            <password-icon
              :password-focus="passwordFocus"
              class="custom-ml-10"
            />
            <input
              v-model="code"
              placeholder="인증번호를 입력해주세요"
              autocomplete="off"
              class="text_label login_placeholder"
              min="6"
              tabindex="1"
              @input="changeCode"
            />
            <font size="16" color="red" weight="semiBold" class="custom-mr-10">
              {{ timerLabel }}
            </font>
          </label>
        </div>
        <uni-button width="100" height="44" :click="recreateCode">
          <uni-font color="white" size="12">인증번호 재전송</uni-font>
        </uni-button>
      </div>
      <uni-font size="14" class="w-100 text-center custom-pt-15">
        등록하신 이메일로 인증번호 6자리를 전송하였습니다.
      </uni-font>
      <div class="w-100 custom-mt-15">
        <uni-button
          class="w-100 h-100"
          type="submit"
          variant="black"
          @keydown.enter="mfaLogin"
        >
          <uni-font color="white" weight="semiBold" size="18">확인</uni-font>
        </uni-button>
      </div>
    </form>
    <LoginPasswordChangeModal
      :userId="loginInfo.userId"
      :userOriginPwd="loginInfo.userPwd"
      :passwordLimit="passwordLimit"
      :passwordChangeRequired="passwordChangeRequired"
      @closeModal="closeModal"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref } from "vue-property-decorator";
import { USER } from "@/types/user";
import { IUserLogin } from "@/interface/user/user";
import IdIcon from "@/components/icons/login/IdIcon.vue";
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import LoginPasswordChangeModal from "@/components/modal/login/LoginPasswordChangeModal.vue";

@Component({
  components: {
    IdIcon,
    PasswordIcon,
    LoginPasswordChangeModal,
  },
})
export default class LoginComponent extends Vue {
  @Ref() passwordInput: HTMLElement;

  idFocus = false;
  passwordFocus = false;
  idActive = "id_focus";
  passwordActive = "password_focus";
  idFocusInput = "id_focus_input";
  pwFocusInput = "password_focus_input";

  loginInfo: IUserLogin;
  failCount: number;
  accountLock = false;
  isLoading = false;
  passwordLimit = false;
  passwordChangeRequired = false;

  passwordConfirm = false;
  code: number | null = null;
  expiresMinutes = 4;
  expiresSeconds = 60;
  expires = 300;
  expiresInterval: number;

  constructor() {
    super();
    this.loginInfo = USER.LoginInit();
    this.failCount = 0;
  }

  get timerLabel() {
    const isStart =
      Math.ceil(this.expires / 60) - 1 === this.expiresMinutes &&
      this.expiresSeconds === 60;
    return `0${isStart ? 5 : this.expiresMinutes}:${
      this.expiresSeconds < 10
        ? `0${this.expiresSeconds}`
        : isStart
        ? "00"
        : this.expiresSeconds
    }`;
  }

  resetFail() {
    this.failCount = 0;
    this.accountLock = false;
  }

  async login(): Promise<void> {
    try {
      if (!this.idValidation()) return;
      if (!this.pwdValidation()) return;

      const sendData = this.createSendData();
      const { data } = await this.axios.post(`/admin/user/login`, sendData);
      if (data) {
        const { result, token, loginFailedCount, ACCOUNT_LOCK } = data;
        this.accountLock = ACCOUNT_LOCK || false;
        this.failCount = loginFailedCount || 0;
        if (result) {
          this.passwordConfirm = result;
          this.$store.commit("setAccessMfa", token);
          this.onTimer();
        }
      }
    } catch (e) {
      this.passwordConfirm = false;
      this.clearTimer();
    }
  }

  async mfaLogin(): Promise<void> {
    if (!this.code) {
      this.$toast.error("인증번호를 입력하세요.");
      return;
    }
    try {
      const { data } = await this.axios.post(`/admin/user/mfa-login`, {
        userId: this.loginInfo.userId,
        code: this.code,
      });
      const { result, passwordLimit, passwordChangeRequired } = data;
      if (result) {
        this.$store.commit("setAccessMfa", "");
        if (passwordLimit || passwordChangeRequired) {
          this.passwordLimit = passwordLimit;
          this.passwordChangeRequired = passwordChangeRequired;
          this.clearTimer();
          this.$bvModal.show("login-password-change-modal");
        } else {
          await this.$store.dispatch("login", { data });
          await this.$store.commit("setIsFirst", true);
          await this.$store.commit("setLastLoginDate", data.lastLoginDate);
          await this.$router.push("/list");
        }
      } else {
        this.passwordConfirm = false;
        this.clearTimer();
      }
    } catch (e) {
      this.passwordConfirm = false;
      this.clearTimer();
    }
  }

  async recreateCode() {
    if (!this.idValidation()) return;
    if (!this.pwdValidation()) return;
    try {
      const sendData = this.createSendData();
      const { data } = await this.axios.post(`/admin/user/login`, sendData);
      const { result } = data;
      if (result) {
        this.clearTimer();
        this.$toast.success("인증번호가 재전송되었습니다.");
        this.expiresMinutes = 4;
        this.expiresSeconds = 60;
        this.expires = 300;
        this.onTimer();
      } else {
        this.passwordConfirm = false;
        this.clearTimer();
      }
    } catch (e) {
      this.passwordConfirm = false;
      this.clearTimer();
    }
  }

  expiresTimer() {
    this.expiresSeconds--;
    this.expires--;
    if (this.expires === 0) {
      this.expiresMinutes = 0;
      this.expiresSeconds = 0;
      this.clearTimer();
    }
    if (this.expiresSeconds < 0) {
      this.expiresMinutes--;
      this.expiresSeconds = 59;
    }
  }

  onTimer() {
    this.expiresInterval = setInterval(this.expiresTimer, 1000);
  }

  clearTimer() {
    clearInterval(this.expiresInterval);
  }

  closeModal() {
    this.back();
  }

  back() {
    this.loginInfo.userPwd = "";
    this.code = null;
    this.expiresMinutes = 4;
    this.expiresSeconds = 60;
    this.expires = 300;
    this.clearTimer();
    this.passwordConfirm = false;
  }

  changeCode(e: any) {
    let v = e.target.value.replace(/[^0-9]/g, "");
    if (6 <= v.length) v = v.slice(0, 6);
    e.target.value = v;
    this.code = +v;
  }

  idValidation(): boolean {
    const isIdNull = !this.loginInfo.userId;
    if (isIdNull) {
      this.$toast.error("아이디를 입력해 주세요.");
      return false;
    }
    return true;
  }

  pwdValidation(): boolean {
    const isPwNull = !this.loginInfo.userPwd;
    if (isPwNull) {
      this.$toast.error("비밀번호를 입력해 주세요.");
      return false;
    }
    return true;
  }

  createSendData(): IUserLogin {
    const { userId, userPwd } = this.loginInfo;
    return {
      userId,
      userPwd,
    };
  }

  get failState(): boolean {
    return this.failCount >= 5;
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  width: 100%;
  height: 100%;
  display: flex;

  .login-form {
    width: 520px;
    height: 100%;
    min-height: 524px;
    max-height: 747px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    border-radius: 35px;
    background: $white;
    margin: auto 120px auto 0;
    padding: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .login-input-wrapper {
      border: 1px solid $gray4;
      border-radius: 8px;
      display: flex;
      width: 100%;
      height: 44px;
      align-items: center;

      input::placeholder {
        color: $black2;
        font-weight: 400;
        line-height: 15.47px;
      }
    }

    .login-description-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    /* input focus */
    .id_focus,
    .password_focus {
      border: 1px solid $point-color !important;
      padding: 0 !important;
    }

    .login_placeholder {
      padding: 13px 0 13px 18px;
      width: 100%;
      height: 100%;
      border: none;
    }

    .id_focus_input,
    .password_focus_input {
      color: $black !important;
    }

    .text_label {
      width: 80%;
      background: $white;
      border: none;
      outline: none;
      color: $textPlaceholder;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.64px;
      height: 100%;
    }

    .login-wording {
      font-size: 14px;
      color: $textPlaceholder;
      font-weight: 400;
      letter-spacing: -0.56px;
      line-height: 20px;
    }

    .simple-login-division {
      border-bottom: 1px solid #a3a3a3;
      width: 109px;
      height: 0.8px;
    }

    .text-2 {
      color: transparent;
      font-size: 1em;
      letter-spacing: -0.64px;
      padding-top: 30px;
      padding-bottom: 36px;
      min-height: 18px;
      min-width: 190px;
    }

    /* 버튼 */
    .login-button-wrapper {
      width: 100%;
      margin-top: 42px;
    }

    .input_password_type_button {
      position: relative;
      left: 6px;
      bottom: 2px;
    }
  }
}
</style>
