<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="passwordFocus ? 'active' : ''"
      d="M3.3335 11.5C3.3335 9.61438 3.3335 8.67157 3.91928 8.08579C4.50507 7.5 5.44788 7.5 7.3335 7.5H12.6668C14.5524 7.5 15.4953 7.5 16.081 8.08579C16.6668 8.67157 16.6668 9.61438 16.6668 11.5V11.5C16.6668 14.3284 16.6668 15.7426 15.7881 16.6213C14.9095 17.5 13.4953 17.5 10.6668 17.5H9.33349C6.50507 17.5 5.09086 17.5 4.21218 16.6213C3.3335 15.7426 3.3335 14.3284 3.3335 11.5V11.5Z"
      stroke="#575757"
    />
    <path
      :class="passwordFocus ? 'active' : ''"
      d="M13.3337 6.66667V5.83333C13.3337 3.99239 11.8413 2.5 10.0003 2.5V2.5C8.15938 2.5 6.66699 3.99239 6.66699 5.83333V6.66667"
      stroke="#575757"
      stroke-linecap="round"
    />
    <circle
      cx="10.0002"
      cy="12.5"
      r="1.66667"
      :class="passwordFocus ? 'active' : ''"
      fill="#575757"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PasswordIcon extends Vue {
  @Prop({ default: false }) passwordFocus: boolean;
}
</script>

<style lang="scss" scoped>
.active {
  stroke: $point-color;
}
</style>
