<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      :class="idFocus ? 'active' : ''"
      x="3.3335"
      y="5"
      width="13.3333"
      height="10"
      rx="2"
      stroke="#575757"
    />
    <path
      :class="idFocus ? 'active' : ''"
      d="M3.3335 7.5L9.10573 10.3861C9.66879 10.6676 10.3315 10.6676 10.8946 10.3861L16.6668 7.5"
      stroke="#575757"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IdIcon extends Vue {
  @Prop({ default: false }) idFocus: boolean;
}
</script>

<style scoped lang="scss">
.active {
  stroke: $point-color;
}
</style>
