<template>
  <div class="project-quota-container">
    <div v-if="isPanel" class="d-flex align-items-center">
      <div class="title">
        <font color="textTitle" size="14" weight="medium">성별</font>
      </div>
      <div class="round-box">
        <div class="inner-box" :class="{ contacted: quota.gender === '0' }">
          전체
        </div>
        <div class="inner-box" :class="{ contacted: quota.gender === '1' }">
          남성
        </div>
        <div class="inner-box" :class="{ contacted: quota.gender === '2' }">
          여성
        </div>
      </div>
    </div>
    <div v-if="isPanel" class="d-flex align-items-start custom-pt-10">
      <div class="title">
        <font color="textTitle" size="14" weight="medium">연령</font>
      </div>
      <div class="round-box">
        <template v-if="quota.ageUnit === '0'">
          <div class="inner-box contacted">전체</div>
        </template>
        <template v-if="quota.ageUnit === '1'">
          <div class="inner-box" :class="{ contacted: quotaAge('2', 10) }">
            20-29
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('3', 10) }">
            30-39
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('4', 10) }">
            40-49
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('5', 10) }">
            50-59
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('6', 10) }">
            60-69
          </div>
        </template>
        <template v-else>
          <div class="inner-box" :class="{ contacted: quotaAge('2', 5) }">
            20-24
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('3', 5) }">
            25-29
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('4', 5) }">
            30-34
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('5', 5) }">
            35-39
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('6', 5) }">
            40-44
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('7', 5) }">
            45-49
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('8', 5) }">
            50-54
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('9', 5) }">
            55-59
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('10', 5) }">
            60-64
          </div>
          <div class="inner-box" :class="{ contacted: quotaAge('11', 5) }">
            65-69
          </div>
        </template>
      </div>
    </div>
    <div v-if="isPanel" class="d-flex align-items-center custom-pt-10">
      <div class="title">
        <font color="textTitle" size="14" weight="medium">지역</font>
      </div>
      <div class="round-box">
        <div class="inner-box" :class="{ contacted: quotaArea('4') }">전국</div>
        <div class="inner-box" :class="{ contacted: quotaArea('1') }">서울</div>
        <div class="inner-box" :class="{ contacted: quotaArea('2') }">
          인천/경기
        </div>
        <div class="inner-box" :class="{ contacted: quotaArea('3') }">
          지방 5대광역시
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      :class="isPanel ? 'custom-pt-10' : ''"
    >
      <div style="width: 100px">
        <font color="textTitle" size="14" weight="medium">샘플 수</font>
      </div>
      <div>
        <font color="textBody" size="14">{{ quota.responseCount }}명</font>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SimpleSurveyQuota } from "@/api";

@Component({})
export default class ProjectViewQuota extends Vue {
  @Prop({
    default: {
      responseCount: 0,
      gender: "",
      ageUnit: "",
      age10: [],
      age5: [],
      quotaSet: "",
      quotaCount: {},
      area: [],
    },
  })
  quota?: SimpleSurveyQuota;
  @Prop({ default: false }) isPanel?: boolean;

  quotaAge(str: string, age: number) {
    let result = false;
    if (this.quota) {
      if (age === 10) {
        result = this.quota?.age10?.includes(str);
      } else {
        result = this.quota?.age5?.includes(str);
      }
    }
    return result;
  }

  quotaArea(area) {
    let result = false;
    this.quota?.area?.forEach((a) => {
      if (a === area) {
        result = true;
      }
    });
    return result;
  }
}
</script>

<style scoped lang="scss">
.project-quota-container {
  .title {
    width: 100px;
  }

  .round-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .inner-box {
    border: 1px solid $line;
    text-align: center;
    background-color: $placeholder;
    min-width: 70px;
    width: fit-content;
    margin: 0 2px;
    border-radius: 5px;
    padding: 3px 6px;
    font-size: 15px;
  }

  .contacted {
    border-color: $point-color;
    background-color: #f5fbfd;
    color: $point-color;
    font-weight: bold;
  }
}
</style>
