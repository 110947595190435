<template>
  <div>
    <b-row>
      <b-col cols="12">
        <font size="15" color="textTitle">
          사용중인 아이디는 <b>{{ userId.toLowerCase() }}</b> 입니다.
        </font>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row class="mt-3 align-items-center">
          <b-col md="3" xs="12" class="text-left mt-1">
            <font size="15" color="textTitle">새 비밀번호 입력</font></b-col
          >
          <b-col md="9" xs="12" class="mt-1">
            <b-form-input
              type="password"
              v-model="newPwd"
              :state="validationPassword"
              autocomplete="false"
              class="find-fw-email-input"
              placeholder="특수문자를 포함한 8글자 이상"
            />
            <b-form-text
              >영문 대/소문자,숫자,특수문자($,@,!,%,*,#,?,&)를 포함한 8글자 이상
              20글자 이하으로 적어주세요</b-form-text
            >
            <b-form-invalid-feedback :state="validationPassword">
              {{ validationPwdMessage }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="3" xs="12" class="text-left mt-1">
            <font size="15" color="textTitle">새 비밀번호 재입력</font>
          </b-col>
          <b-col md="9" xs="12" class="mt-1">
            <b-form-input
              type="password"
              v-model="rePwd"
              autocomplete="false"
              :state="validationConfPassword"
              class="find-fw-email-input"
              placeholder="새로운 비밀번호를 다시 한 번 입력해주세요"
            />
            <b-form-text>비밀번호를 한번 더 입력해주세요</b-form-text>
            <b-form-invalid-feedback :state="validationConfPassword">
              {{ validationPwdConfMessage }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="d-flex justify-content-end">
            <button
              type="button"
              class="confirm-button w-25"
              @click="setPassword"
            >
              비밀번호 재설정
            </button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import router from "@/router";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component
export default class SetNewPw extends Vue {
  @Prop({ required: true }) userId!: string;
  newPwd: string;
  rePwd: string;

  constructor() {
    super();
    this.newPwd = "";
    this.rePwd = "";
  }

  async setPassword() {
    if (!this.validPassword()) return;

    try {
      const sendData = {
        userId: this.userId,
        userPwd: this.newPwd,
      };

      const { data } = await this.axios.post(
        "/admin/user/set-login-new-password",
        sendData
      );
      const { result, message } = data;

      if (result) {
        this.$common.makeToast(message, ToastVariant.SUCCESS, this.$bvToast);
        setTimeout(() => {
          router.replace({ name: "login" });
        }, 1000);
      } else {
        this.$common.makeToast(message, ToastVariant.DANGER, this.$bvToast);
      }
    } catch (e) {
      console.log(e);
    }
  }

  validPassword() {
    if (this.newPwd !== this.rePwd) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK1,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }

    if (this.newPwd.length < 8) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK2,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }

    if (this.newPwd.length > 20) {
      this.$common.makeToast(
        ToastMessage.PASSWORD_CHECK3,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    }
    return true;
  }

  get validationPwdMessage(): string | void {
    return "비밀번호의 형식을 맞춰주십시오.";
  }

  get validationPwdConfMessage(): string | void {
    return "비밀번호가 같지 않습니다.";
  }

  get validationPassword(): boolean | null {
    const pwdRegExp =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$@!%*#?&])[a-zA-Z\d$@!%*#?&]{8,20}$/;

    const isOkPwdReg = pwdRegExp.test(this.newPwd);
    const isNull = this.newPwd.length === 0;

    if (isNull) return null;
    return isOkPwdReg;
  }

  get validationConfPassword(): boolean | null {
    const isNull = this.rePwd.length === 0;
    if (isNull) return null;
    return this.newPwd === this.rePwd;
  }
}
</script>

<style scoped lang="scss">
.password-change-container {
  background-color: red;
  //width: 100%;
  //height: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;

  .input-wrapper {
    width: 60%;

    .policy:hover p,
    .private:hover p {
      font-weight: 700;
    }
  }
}
</style>
