<template>
  <div class="check-list-container">
    <div class="d-flex">
      <div class="title">
        <font color="textTitle" size="15" weight="medium">개인정보</font>
      </div>
      <div class="d-flex">
        <b-form-radio-group v-model="checkList.privateInfo">
          <b-form-radio
            class="mr-5"
            name="private-radios"
            :value="true"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'privateInfo')"
            >해당</b-form-radio
          >
          <b-form-radio
            name="private-radios"
            :value="false"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'privateInfo')"
            >해당없음</b-form-radio
          >
        </b-form-radio-group>
      </div>
    </div>
    <div class="d-flex custom-mt-5">
      <div class="title">
        <font color="textTitle" size="15" weight="medium">민감정보</font>
      </div>
      <div class="d-flex">
        <b-form-radio-group v-model="checkList.sensitiveInfo">
          <b-form-radio
            class="mr-5"
            name="sensitive-radios"
            :value="true"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'sensitiveInfo')"
            >해당</b-form-radio
          >
          <b-form-radio
            name="sensitive-radios"
            :value="false"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'sensitiveInfo')"
            >해당없음</b-form-radio
          >
        </b-form-radio-group>
      </div>
    </div>
    <div class="d-flex custom-mt-5">
      <div class="title">
        <font color="textTitle" size="15" weight="medium">정치 관련</font>
      </div>
      <div class="d-flex">
        <b-form-radio-group v-model="checkList.political">
          <b-form-radio
            class="mr-5"
            name="political-radios"
            :value="true"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'political')"
            >해당</b-form-radio
          >
          <b-form-radio
            name="political-radios"
            :value="false"
            :disabled="isReject || isTest"
            @change="changeRadio($event, 'political')"
            >해당없음</b-form-radio
          >
        </b-form-radio-group>
      </div>
    </div>
    <div class="d-flex custom-mt-5 custom-mb-5">
      <div class="title">
        <font
          color="textTitle"
          size="15"
          weight="medium"
          style="white-space: nowrap"
          >반려 사유 작성</font
        >
      </div>
      <label class="d-flex align-items-end">
        <VueEditor
          id="checklist-editor"
          editorOption
          v-model="checkList.comment"
          :disabled="
            isReject ||
            isTest ||
            !(
              checkList.sensitiveInfo ||
              checkList.privateInfo ||
              checkList.political
            )
          "
        />
        <div class="custom-ml-16 h-100 d-flex flex-column justify-content-end">
          <!--          <uni-button-->
          <!--            class="custom-mb-4"-->
          <!--            width="150"-->
          <!--            height="31"-->
          <!--            variant="primary"-->
          <!--            :disabled="isTest"-->
          <!--            :click="clickLoadOriginComment"-->
          <!--          >-->
          <!--            <font color="white" size="14" weight="medium"-->
          <!--              >기존 검수 항목 불러오기</font-->
          <!--            >-->
          <!--          </uni-button>-->
          <uni-button
            v-if="!isReject"
            :width="80"
            height="31"
            variant="gray2"
            :click="checkListActive"
          >
            <font color="white" size="14" weight="medium">반려</font>
          </uni-button>
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { SimpleSurveyChecklistInfo } from "@/api";
import { VueEditor } from "vue2-editor";

@Component({
  components: { VueEditor },
})
export default class ProjectViewCheckList extends Vue {
  @Prop() private readonly isRefunded: boolean;
  @Prop() snum: number;
  @Prop() owner: string;
  @Prop() isTest: boolean;
  @Prop() isReject: boolean;
  @Prop() simpleSurveyChecklist: SimpleSurveyChecklistInfo;

  checkList: SimpleSurveyChecklistInfo = {
    privateInfo: false,
    sensitiveInfo: false,
    political: false,
    comment: "",
  };
  // originComment = "";
  // originPrivateInfo = false;
  // originSensitiveInfo = false;
  // originPolitical = false;

  changeRadio(value: boolean, name: string) {
    const { privateInfo, sensitiveInfo, political } = this.checkList;
    if (!(privateInfo || sensitiveInfo || political)) {
      const confirm = window.confirm(
        "검수 항목을 모두 해당없음으로 변경시 반려 사유 작성 내용은 삭제됩니다."
      );
      if (!confirm) {
        this.checkList[name] = !confirm;
        return;
      }
      this.checkList.comment = "";
    }
  }

  // clickLoadOriginComment() {
  //   this.checkList.comment = this.originComment;
  //   this.checkList.privateInfo = this.originPrivateInfo;
  //   this.checkList.sensitiveInfo = this.originSensitiveInfo;
  //   this.checkList.political = this.originPolitical;
  // }

  created() {
    const { privateInfo, sensitiveInfo, political, comment } =
      this.simpleSurveyChecklist;
    // this.originComment = comment || "";
    // this.originSensitiveInfo = sensitiveInfo || false;
    // this.originPolitical = political || false;
    // this.originPrivateInfo = privateInfo || false;
    this.checkList = {
      privateInfo,
      sensitiveInfo,
      political,
      comment,
    };
  }

  @Emit("checkListActive")
  async checkListActive() {
    const { comment } = this.checkList;
    if (!comment) {
      this.$toast.error("반려 사유를 작성해주세요.");
      return;
    }
    return this.checkList;
  }

  @Watch("simpleSurveyChecklist")
  watchList() {
    this.checkList = {
      ...this.simpleSurveyChecklist,
    };
  }
}
</script>
<style scoped lang="scss">
.check-list-container {
  .title {
    width: 100px;
  }

  .textarea {
    border: 1px solid $line;
    color: $textSub;
    width: 450px;
    min-height: 120px;
    height: auto;
    overflow-y: auto;
    padding: 4px 6px;
    font-size: 14px;
    margin-right: 16px;
  }
}
</style>
<style>
.check-list-container {
  #checklist-editor {
    border: 1px solid #dee2e6;
    width: 500px;
  }
  .ql-editor {
    padding: 10px;
  }
  .ql-toolbar {
    display: none;
  }
  .ql-disabled {
    background-color: #eeeeee;
  }
}
</style>
